import { useTranslation } from '@crehana/i18n';

import { SUPPORT_CARD_TRANSLATION_KEY } from '../constants';
import {
  supportCardLocaleEn,
  supportCardLocaleEs,
  supportCardLocalePt,
} from '../i18n';
import type { SupportCardLocaleKeys } from '../types';

export const useSupportCardTranslation = () => {
  const { t, addResourceBundle } = useTranslation<SupportCardLocaleKeys>(
    SUPPORT_CARD_TRANSLATION_KEY,
  );

  addResourceBundle(
    {
      es: supportCardLocaleEs.es,
      pt: supportCardLocalePt.pt,
      en: supportCardLocaleEn.en,
    },
    { injectOnce: true },
  );

  return t;
};
