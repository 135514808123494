import { date } from "@crehana/utils";
export function transformEdgeToCourse(edge) {
  if (edge !== null && edge !== void 0 && edge.node) {
    var _edge$node$level;
    var durationInSeconds = edge.node.duration;
    // Convert duration milliseconds to '1h 30m' format
    var duration = date.toMinutes(durationInSeconds);
    var promoImage = edge.node.promoImage || '';
    var newObject = {
      originalId: String(edge.node.originalId),
      title: edge.node.title || '',
      link: edge.node.url || '',
      coverImage: promoImage,
      duration: duration,
      level: ((_edge$node$level = edge.node.level) === null || _edge$node$level === void 0 ? void 0 : _edge$node$level.name) || ''
    };
    return newObject;
  }
  return undefined;
}