import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["addResourceBundle"];
import { useTranslation } from "@crehana/i18n";
import { CATALOG_EMPTY_STATE_TRANSLATION_KEY } from "../constants";
import locales from "../i18n";
export var useCatalogEmptyStateTranslations = function useCatalogEmptyStateTranslations() {
  var _useTranslation = useTranslation(CATALOG_EMPTY_STATE_TRANSLATION_KEY),
    addResourceBundle = _useTranslation.addResourceBundle,
    rest = _objectWithoutProperties(_useTranslation, _excluded);
  addResourceBundle(locales);
  return rest;
};