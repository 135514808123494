/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CourseTypeEnum {
  B2B = "B2B",
  B2B_CLASSROOMS = "B2B_CLASSROOMS",
  DEGREE = "DEGREE",
  FREE = "FREE",
  FREEMIUM = "FREEMIUM",
  HIDDEN = "HIDDEN",
  MEMBERSHIP = "MEMBERSHIP",
  PAID = "PAID",
  PARTNER = "PARTNER",
  ROCKSTAR = "ROCKSTAR",
  TEMPORAL_ACCESS = "TEMPORAL_ACCESS",
}

export enum OrderDirectionEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ResourceTypeEnum {
  FILE = "FILE",
  URL = "URL",
}

export enum SessionTimeLineEnum {
  FOR_START = "FOR_START",
  FUTURE = "FUTURE",
  LIVE = "LIVE",
  PAST = "PAST",
}

export enum SessionTimeZoneEnum {
  TIME_ZONE_ES_AR = "TIME_ZONE_ES_AR",
  TIME_ZONE_ES_CL = "TIME_ZONE_ES_CL",
  TIME_ZONE_ES_CO = "TIME_ZONE_ES_CO",
  TIME_ZONE_ES_ES = "TIME_ZONE_ES_ES",
  TIME_ZONE_ES_MX = "TIME_ZONE_ES_MX",
  TIME_ZONE_ES_PE = "TIME_ZONE_ES_PE",
  TIME_ZONE_PT_BR = "TIME_ZONE_PT_BR",
}

export enum SessionTypeEnum {
  ONLINE = "ONLINE",
  PRESENCE = "PRESENCE",
}

export enum StatusProjectEnum {
  CERTIFIED = "CERTIFIED",
  DRAFT = "DRAFT",
  FINISHED = "FINISHED",
  OBSERVED = "OBSERVED",
  PUBLISHED = "PUBLISHED",
  RESOLVED = "RESOLVED",
}

export enum UserStatusEnum {
  CONFIRMED = "CONFIRMED",
  NO_CONFIRMED = "NO_CONFIRMED",
  REJECTED = "REJECTED",
}

export enum UserTeamOrderByEnum {
  ORGANIZATION_TEAM_END_DATE = "ORGANIZATION_TEAM_END_DATE",
  USER_TEAM_EXPIRATION_DATE = "USER_TEAM_EXPIRATION_DATE",
}

export enum UserTeamSearchFilterEnum {
  TEAM_SEARCH_FILTER_ALL = "TEAM_SEARCH_FILTER_ALL",
  TEAM_SEARCH_FILTER_COMPLETED = "TEAM_SEARCH_FILTER_COMPLETED",
  TEAM_SEARCH_FILTER_CURRENT = "TEAM_SEARCH_FILTER_CURRENT",
  TEAM_SEARCH_FILTER_DISABLED = "TEAM_SEARCH_FILTER_DISABLED",
  TEAM_SEARCH_FILTER_EXPIRED = "TEAM_SEARCH_FILTER_EXPIRED",
}

/**
 * Input for retrieve a live session [Query]
 */
export interface LiveSessionGetInput {
  organization_id: string;
  id: string;
}

/**
 * Input for retrieve a live session resource [Query]
 */
export interface LiveSessionGetResourceInput {
  organization_id?: number | null;
  live_session_id: string;
}

export interface UserOrganizationTeamInput {
  organization_id?: number | null;
  organization_team_id: number;
}

/**
 * Input for update a live session users status [Mutation]
 */
export interface UserSessionChangeStatusInput {
  organization_id?: number | null;
  live_session_id: string;
  user_session_id?: string | null;
  status: UserStatusEnum;
}

export interface UserTeamInputOrderBy {
  field: UserTeamOrderByEnum;
  direction: OrderDirectionEnum;
}

export interface UserTeamRewardInput {
  organization_id?: number | null;
  organization_team_id: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
