import { SUPPORT_CARD_TRANSLATION_KEY } from '../constants';

export default {
  pt: {
    [SUPPORT_CARD_TRANSLATION_KEY]: {
      SUPPORT_CARD_CALENDLY_TITLE: 'Agende uma chamada de boas-vindas',
      SUPPORT_CARD_CALENDLY_DESCRIPTION:
        'Se precisar de ajuda com sua assinatura, agende uma ligação de suporte com um agente de Customer Happiness por meio do Calendly.',
      SUPPORT_CARD_CALENDLY_DESCRIPTION_FIT:
        'Agende uma ligação de suporte exclusiva para você sempre que quiser.',
      SUPPORT_CARD_CALENDLY_CTA: 'Agendar videochamada',
      SUPPORT_CARD_ACADEMIC_ADVISING_TITLE: 'Aconselhamento acadêmico',
      SUPPORT_CARD_ACADEMIC_ADVISING_DESCRIPTION_FIT:
        'Você quer receber consultorias de vez em quando?',
      SUPPORT_CARD_ACADEMIC_ADVISING_DESCRIPTION:
        'Nossos agentes de call center entrarão em contato em breve para ajudá-lo com seu plano de estudos, planos de aprendizado e como atingir seus objetivos. Para isso, precisaremos que você confirme seu número de telefone.',
      SUPPORT_CARD_HELP_CENTER_TITLE: 'Centro de ajuda',
      SUPPORT_CARD_HELP_CENTER_DESCRIPTION:
        'Nossa equipe de satisfação do cliente reuniu uma série de artigos de ajuda com tudo que você precisa para começar a aprender.',
      SUPPORT_CARD_HELP_CENTER_DESCRIPTION_FIT:
        'Verifique se a sua dúvida foi resolvida nas perguntas mais frequentes.',
      SUPPORT_CARD_HELP_CENTER_CTA: 'Ir para a Central de Ajuda',

      SUPPORT_CARD_PHONE_EDITOR_LABEL: 'Número de telefone',
      SUPPORT_CARD_PHONE_EDITOR_DESCRIPTION:
        'Não se preocupe que não iremos publicá-lo',
      SUPPORT_CARD_PHONE_EDITOR_CONFIRM_NUMBER: 'Confirmar número',
      SUPPORT_CARD_PHONE_EDITOR_ADVISING_REQUIRED_NUMBER:
        '* Deve fornecer seu número de contato',
      SUPPORT_CARD_PHONE_EDITOR_CTA_LABEL: 'Editar meu número',
      SUPPORT_CARD_PHONE_UPDATE_CTA_LABEL: 'Atualize seu número',
    },
  },
};
