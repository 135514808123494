import * as React from 'react';

import { Container, Skeleton } from '@crehana/ui';

interface TrackSectionSkeletonProps {
  isDark?: boolean;
  className?: string;
}

const TrackSectionSkeleton: React.FC<
  React.PropsWithChildren<TrackSectionSkeletonProps>
> = ({ isDark, className }) => {
  return (
    <Container type="dashboard" className={className}>
      <Skeleton isDark={isDark}>
        <Skeleton className="w-full h-212 sm:h-360" isDark={isDark} />
      </Skeleton>
    </Container>
  );
};

export default TrackSectionSkeleton;
