import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";
var usePhoneNumberState = function usePhoneNumberState() {
  var _useState = useState({
      fullNumber: '',
      code: '',
      phoneNumber: '',
      error: '',
      isValid: false
    }),
    _useState2 = _slicedToArray(_useState, 2),
    phoneState = _useState2[0],
    setPhoneState = _useState2[1];
  return {
    phoneState: phoneState,
    setPhoneState: setPhoneState
  };
};
export default usePhoneNumberState;