import { useQuery } from "@apollo/client";
import { notEmpty } from "@crehana/utils";
import parseCourseEnrollment from "../../../../Graphql/Parsers/parseCourseEnrollment";
var GET_ORGANIZATION_COURSES_QUERY = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "LearnGetOrganizationEnrollmentSet"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "organizationSlug"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "first"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Int"
          }
        }
      },
      directives: []
    }],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "me"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "classroomEnrollmentSet"
            },
            arguments: [{
              kind: "Argument",
              name: {
                kind: "Name",
                value: "organizationSlug"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "organizationSlug"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "first"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "first"
                }
              }
            }],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "edges"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "node"
                    },
                    arguments: [],
                    directives: [],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "id"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "course"
                        },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "Field",
                            name: {
                              kind: "Name",
                              value: "id"
                            },
                            arguments: [],
                            directives: []
                          }, {
                            kind: "Field",
                            name: {
                              kind: "Name",
                              value: "dueDate"
                            },
                            arguments: [],
                            directives: []
                          }, {
                            kind: "Field",
                            name: {
                              kind: "Name",
                              value: "enrollments"
                            },
                            arguments: [],
                            directives: []
                          }, {
                            kind: "Field",
                            name: {
                              kind: "Name",
                              value: "courseEndDate"
                            },
                            arguments: [],
                            directives: []
                          }, {
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "CourseEnrollmentCourseFragment"
                            },
                            directives: []
                          }, {
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "CourseFragment"
                            },
                            directives: []
                          }, {
                            kind: "Field",
                            name: {
                              kind: "Name",
                              value: "project"
                            },
                            arguments: [],
                            directives: [],
                            selectionSet: {
                              kind: "SelectionSet",
                              selections: [{
                                kind: "FragmentSpread",
                                name: {
                                  kind: "Name",
                                  value: "CourseEnrollmentProjectFragment"
                                },
                                directives: []
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "CourseEnrollmentCourseFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "CourseNode"
      }
    },
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "originalId"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "promoImage"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "imagePlaceholder"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "duration"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "progress"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "isFree"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "professor"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "originalId"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "fullName"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "courseTypeEnum"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "enrollmentTypeEnum"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "lastVideoLectureUrl"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "lastVideoEvent"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "videoLecture"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "originalId"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "order"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "title"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "module"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "originalId"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "order"
                    },
                    arguments: [],
                    directives: []
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "isOrganizationAssigned"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissionsV2"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "canUploadProject"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "canFeedbackProject"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "catalogSubCategories"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "originalId"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "studioConfigSet"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "approvalRequirementEnum"
            },
            arguments: [],
            directives: []
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "CourseFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "CourseNode"
      }
    },
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "originalId"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "promoImage"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "imagePlaceholder"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "isSavedCourse"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "courseTag"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "textLabel"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "textColor"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "backgroundColor"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preSale"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "launchDate"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "duration"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "totalLessons"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "enrollments"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "level"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "originalId"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "slug"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        alias: {
          kind: "Name",
          value: "description"
        },
        name: {
          kind: "Name",
          value: "shortDescriptionWithoutHtml"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "isEnrollment"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "courseType"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "courseTypeEnum"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "url"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "dashboardUrl"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "enrollmentUrl"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "enrollmentAccessPreviewUrl"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "urlToBuyNow"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "catalogCategories"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "originalId"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "score"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "average"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "professor"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "originalId"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "fullName"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "username"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "prices"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "currencySymbol"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "price"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "realPrice"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "discount"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "trailerSource"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "videoUrl"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "videoSourceType"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "imageUrl"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "isDefault"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "subtitleList"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "subtitleFile"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "languageCode"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "isDefault"
            },
            arguments: [],
            directives: []
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "subtitle"
        },
        arguments: [],
        directives: []
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "CourseEnrollmentProjectFragment"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ProjectNode"
      }
    },
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "originalId"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "projectImageUrl"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "absoluteUrl"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "currentActionState"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "hasCertification"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updatedAt"
        },
        arguments: [],
        directives: []
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "degreeLearningDetail"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "degreeLearning"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "degreeSyllabus"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "statusEnum"
                    },
                    arguments: [],
                    directives: []
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 742,
    source: {
      body: "#import \"../../../Graphql/Fragments/CourseEnrollmentCourseFragment.graphql\"\n#import \"../../../Graphql/Fragments/CourseFragment.graphql\"\n#import \"../../../Graphql/Fragments/CourseEnrollmentProjectFragment.graphql\"\n\nquery LearnGetOrganizationEnrollmentSet($organizationSlug: String!, $first: Int!) {\n  me {\n    id\n    classroomEnrollmentSet(organizationSlug: $organizationSlug, first: $first) {\n      edges {\n        node {\n          id\n          course {\n            id\n            dueDate\n            enrollments\n            courseEndDate\n            ...CourseEnrollmentCourseFragment\n            ...CourseFragment\n            project {\n              ...CourseEnrollmentProjectFragment\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
var useOrganizationCourses = function useOrganizationCourses(_ref) {
  var _data$me, _data$me$classroomEnr;
  var skip = _ref.skip,
    organizationSlug = _ref.organizationSlug;
  var _useQuery = useQuery(GET_ORGANIZATION_COURSES_QUERY, {
      variables: {
        first: 20,
        organizationSlug: organizationSlug
      },
      skip: !organizationSlug || skip,
      fetchPolicy: 'no-cache'
    }),
    data = _useQuery.data,
    loading = _useQuery.loading,
    error = _useQuery.error;
  var courses = ((data === null || data === void 0 ? void 0 : (_data$me = data.me) === null || _data$me === void 0 ? void 0 : (_data$me$classroomEnr = _data$me.classroomEnrollmentSet) === null || _data$me$classroomEnr === void 0 ? void 0 : _data$me$classroomEnr.edges) || []).map(function (el) {
    var _el$node;
    return el ? (_el$node = el.node) === null || _el$node === void 0 ? void 0 : _el$node.course : undefined;
  }).filter(notEmpty).map(function (course) {
    return parseCourseEnrollment({
      course: course
    });
  });
  return {
    organizationCourses: courses,
    loading: loading,
    error: error
  };
};
export default useOrganizationCourses;