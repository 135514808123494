import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { notEmpty } from "@crehana/utils";
export var parseCatalogCategory = function parseCatalogCategory(category) {
  return _objectSpread(_objectSpread({}, category), {}, {
    absoluteUrl: category.absoluteUrl || '',
    courses: category.courses || 0,
    icon: category.icon || '',
    subcategories: category.subcategories ? category.subcategories.filter(notEmpty) : [],
    softwares: category.softwares ? category.softwares.filter(notEmpty) : []
  });
};
var parseCatalogCategories = function parseCatalogCategories(categories) {
  return (categories || []).filter(notEmpty).map(parseCatalogCategory);
};
export default parseCatalogCategories;