import { useCallback } from 'react';
import * as React from 'react';

import { useMutation } from '@apollo/client';

import { Clamp, LazyImage, PrimaryButton } from '@crehana/ui';
import { formatters } from '@crehana/utils';

import SEND_LIVE_SUBSCRIPTION from './graphql/SubscribeToLive.graphql';
import {
  SubscribeToLive,
  SubscribeToLiveVariables,
} from './graphql/types/SubscribeToLive';
import useOrganizationLivesTranslations from './hooks/useOrganizationLivesTranslations';

type OrganizationLiveCardProps = {
  originalId?: number | null;
  imageUrl?: string | null;
  title?: string | null;
  url?: string | null;
  duration?: number;
  isDark?: boolean;
  presenter?: string | null;
  isSubscribed?: boolean | null;
};

const OrganizationLiveCard: React.FC<
  React.PropsWithChildren<OrganizationLiveCardProps>
> = ({
  originalId,
  imageUrl,
  title,
  url,
  isDark,
  duration,
  presenter,
  isSubscribed,
}) => {
  const { t } = useOrganizationLivesTranslations();

  const [onSubscription] = useMutation<
    SubscribeToLive,
    SubscribeToLiveVariables
  >(SEND_LIVE_SUBSCRIPTION, {
    variables: {
      live: originalId || 0,
      reason: 'reminder',
    },
  });

  const onClick = useCallback(() => {
    if (url) {
      if (!isSubscribed) {
        onSubscription()
          .then(({ data }) => {
            if (data?.liveSubscription?.success) {
              window.location.href = url;
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        window.location.href = url;
      }
    }
  }, [isSubscribed, onSubscription, url]);

  return (
    <div className="shadow-tooltip mb-24">
      <LazyImage
        className="w-full h-160"
        imgClassName="object-cover"
        src={imageUrl ?? ''}
        imgxParams={{ h: 160, dpr: 2, crop: 'faces' }}
        alt={title || ''}
        isDark={isDark}
      />
      <div className={`p-16 ${isDark ? 'bg-base-lighter-16' : ''}`}>
        <div className="mb-16">
          <Clamp className="font-subtitle3 min-h-40" clamp={2}>
            {title}
          </Clamp>
          {!!duration && (
            <p className="font-body3 text-gray-dark mb-8">
              {t('CARD_DURATION', {
                interpolation: {
                  duration: formatters.formatTime(duration),
                },
              })}
            </p>
          )}
          {presenter && (
            <p className="font-body3 text-gray-dark">
              {t('CARD_PRESENTED_BY', {
                interpolation: {
                  presenter,
                },
              })}
            </p>
          )}
        </div>
        <PrimaryButton
          full
          label={t('CARD_CTA_LABEL')}
          onClick={onClick}
          isDark={isDark}
        />
      </div>
    </div>
  );
};

export default OrganizationLiveCard;
