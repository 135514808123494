// import moment from 'moment';
import base64 from 'base-64';

// moment.locale('es');

// export const formatDate = date => {
//   console.warn(`
//     Hey!!!
//     DON'T USE THIS METHOD, IT USE MOMEMT THAT IS A BIIIIIIGGGG LIBRARY.
//     We have a better helper in Shared/Utils/date.js :)
//   `);
//   const d = moment(date).format('MMM D, YYYY h:mm:ss a');

//   return d
//     .charAt(0)
//     .toUpperCase()
//     .concat(d.slice(1));
// };

// export const humanDate = date => {
//   console.warn(`
//     Hey!!!
//     DON'T USE THIS METHOD, IT USE MOMEMT THAT IS A BIIIIIIGGGG LIBRARY.
//     We have a better helper in Shared/Utils/date.js :)
//   `);
//   const d = moment(date).fromNow();

//   return d
//     .charAt(0)
//     .toUpperCase()
//     .concat(d.slice(1));
// };

var format = function format(time, bigUnit, unit, sep) {
  var minutes = Math.floor(time / 60);
  var seconds = time % 60;
  return "".concat("0".concat(minutes).substr(-2)).concat(bigUnit).concat(sep).concat("0".concat(seconds).substr(-2)).concat(unit);
};
export var formatTime = function formatTime(time) {
  return format(time, 'm', 's', ' : ');
};
export var courseDuration = function courseDuration(time) {
  return format(time / 60, 'h', 'm', '  ');
};
export var graphCursor = function graphCursor(currentPage, pageSize) {
  return base64.encode("arrayconnection:".concat((currentPage - 1) * pageSize - 1));
};
export var getParameterByName = function getParameterByName(name, url) {
  if (!url) return null;
  var paramsName = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp("[?&]".concat(paramsName, "(=([^&#]*)|&|#|$)"));
  var results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};