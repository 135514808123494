import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import parseCatalogCategories from "../../../../Graphql/Parsers/parseCatalogCategories";
var CATALOG_CATEGORIES_QUERY = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "CatalogCategoriesQuery"
    },
    variableDefinitions: [],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "catalogCategory"
        },
        arguments: [],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "originalId"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "slug"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "absoluteUrl"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "courses"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "icon"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "subcategories"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "originalId"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "name"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "slug"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "absoluteUrl"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "courses"
                },
                arguments: [],
                directives: []
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "softwares"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "originalId"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "name"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "slug"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "absoluteUrl"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "courses"
                },
                arguments: [],
                directives: []
              }]
            }
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 317,
    source: {
      body: "query CatalogCategoriesQuery {\n  catalogCategory {\n    originalId\n    name\n    slug\n    absoluteUrl\n    courses\n    icon\n    subcategories {\n      originalId\n      name\n      slug\n      absoluteUrl\n      courses\n    }\n    softwares {\n      originalId\n      name\n      slug\n      absoluteUrl\n      courses\n    }\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
var useCategoriesQuery = function useCategoriesQuery() {
  var _useQuery = useQuery(CATALOG_CATEGORIES_QUERY, {
      fetchPolicy: 'cache-first'
    }),
    data = _useQuery.data,
    loading = _useQuery.loading;
  var categories = useMemo(function () {
    return data !== null && data !== void 0 && data.catalogCategory ? parseCatalogCategories(data.catalogCategory) : [];
  }, [data]);
  return {
    loading: loading,
    categories: categories
  };
};
export default useCategoriesQuery;