import { GET_PHONE_SIMPLE_DIALOG_TRANSLATION_KEY } from '../constants';

export default {
  es: {
    [GET_PHONE_SIMPLE_DIALOG_TRANSLATION_KEY]: {
      GFSD_DEFAULT_TITLE: 'Déjanos tu número de teléfono',
      GFSD_DEFAULT_DESCRIPTION: 'Lo usaremos para agendar tus asesorías.',
      GFSD_PHONE_INPUT_LABEL: 'Número de Teléfono',
      GFSD_PHONE_LEGALS:
        'Autorizo que Crehana use los datos proporcionados para contactarme y recibir promociones, ofertas e información de nuestros productos',
      GFSD_SAVE_NUMBER: 'Guardar número',
      GFSD_SAVE_NUMBER_ERROR: 'Ocurrió un error, vuelve a intentarlo.',
      GFSD_SAVE_NUMBER_SUCCESS: 'Tu número ha sido guardado con éxito.',
    },
  },
};
