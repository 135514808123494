import { useTranslation } from '@crehana/i18n';

import { ORGANIZATION_COURSE_CARD_TRANSLATION_KEY } from '../constants';
import locales from '../i18n';
import { OrganizationCourseCardLocaleKeys } from '../types';

const useOrganizationCourseTranslations = () => {
  const { addResourceBundle, ...translationContextValue } =
    useTranslation<OrganizationCourseCardLocaleKeys>(
      ORGANIZATION_COURSE_CARD_TRANSLATION_KEY,
    );

  addResourceBundle(locales);

  return translationContextValue;
};

export default useOrganizationCourseTranslations;
