import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import { notEmpty } from "@crehana/utils";
var CATALOG_COURSES_QUERY = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "CatalogCoursesQuery"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "newCategories"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "newSubCategories"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "newOrderBy"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "newSoftwares"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "pageSize"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Int"
          }
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "language"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "courses"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "catalogCategories"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "newCategories"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "catalogSubcategories"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "newSubCategories"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "newOrderBy"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "newOrderBy"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "catalogSoftwares"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "newSoftwares"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "language"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "language"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "first"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "pageSize"
            }
          }
        }],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "totalCount"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "edges"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "node"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "originalId"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "title"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "canEnrollCoursePreviewTrial"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "isPreviewTrial"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "url"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "enrollments"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "imagePlaceholder"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "promoImage"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "courseType"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "metaDescription"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    alias: {
                      kind: "Name",
                      value: "description"
                    },
                    name: {
                      kind: "Name",
                      value: "shortDescriptionWithoutHtml"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "urlToBuyNow"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "subtitle"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "isEnrollment"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "professor"
                    },
                    arguments: [],
                    directives: [],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "fullName"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "profilePictureUrl"
                        },
                        arguments: [],
                        directives: []
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "enrollmentAccessPreviewUrl"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "enrollmentUrl"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "urlToAddToCart"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "shoppingCartAdded"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "tagTitle"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "launchDate"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "dueDate"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "categoryObj"
                    },
                    arguments: [],
                    directives: [],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "slug"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "name"
                        },
                        arguments: [],
                        directives: []
                      }]
                    }
                  }, {
                    kind: "Field",
                    alias: {
                      kind: "Name",
                      value: "subcategory"
                    },
                    name: {
                      kind: "Name",
                      value: "subCategory"
                    },
                    arguments: [],
                    directives: [],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "slug"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "name"
                        },
                        arguments: [],
                        directives: []
                      }]
                    }
                  }, {
                    kind: "Field",
                    alias: {
                      kind: "Name",
                      value: "subCategoryObj"
                    },
                    name: {
                      kind: "Name",
                      value: "subCategory"
                    },
                    arguments: [],
                    directives: [],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "slug"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "name"
                        },
                        arguments: [],
                        directives: []
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "duration"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "level"
                    },
                    arguments: [],
                    directives: [],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "name"
                        },
                        arguments: [],
                        directives: []
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 1262,
    source: {
      body: "query CatalogCoursesQuery(\n  $newCategories: String\n  $newSubCategories: String\n  $newOrderBy: String\n  $newSoftwares: String\n  $pageSize: Int!\n  $language: String\n) {\n  courses(\n    catalogCategories: $newCategories\n    catalogSubcategories: $newSubCategories\n    newOrderBy: $newOrderBy\n    catalogSoftwares: $newSoftwares\n    language: $language\n    first: $pageSize\n  ) {\n    totalCount\n    edges {\n      node {\n        originalId\n        title\n        canEnrollCoursePreviewTrial\n        isPreviewTrial\n        url\n        enrollments\n        imagePlaceholder\n        promoImage\n        courseType\n        metaDescription\n        description: shortDescriptionWithoutHtml\n        urlToBuyNow\n        subtitle\n        isEnrollment\n        professor {\n          fullName\n          profilePictureUrl\n        }\n        enrollmentAccessPreviewUrl\n        enrollmentUrl\n        urlToAddToCart\n        shoppingCartAdded\n        tagTitle\n        launchDate\n        dueDate\n        categoryObj {\n          slug\n          name\n        }\n        subcategory: subCategory {\n          slug\n          name\n        }\n        subCategoryObj: subCategory {\n          slug\n          name\n        }\n        duration\n        level {\n          name\n        }\n      }\n    }\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
import { transformEdgeToCourse } from "./utils";
export var useLazyCoursesByCategory = function useLazyCoursesByCategory() {
  var _data$courses2, _data$courses3;
  var _useLazyQuery = useLazyQuery(CATALOG_COURSES_QUERY),
    _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
    fetchCourses = _useLazyQuery2[0],
    _useLazyQuery2$ = _useLazyQuery2[1],
    data = _useLazyQuery2$.data,
    loading = _useLazyQuery2$.loading,
    error = _useLazyQuery2$.error;
  var courses = useMemo(function () {
    var _data$courses, _data$courses$edges;
    return (data === null || data === void 0 ? void 0 : (_data$courses = data.courses) === null || _data$courses === void 0 ? void 0 : (_data$courses$edges = _data$courses.edges) === null || _data$courses$edges === void 0 ? void 0 : _data$courses$edges.map(transformEdgeToCourse).filter(notEmpty)) || [];
  }, [data === null || data === void 0 ? void 0 : (_data$courses2 = data.courses) === null || _data$courses2 === void 0 ? void 0 : _data$courses2.edges]);
  var fetchCoursesHandler = useCallback(function (args) {
    return fetchCourses({
      variables: {
        pageSize: args.pageSize,
        newOrderBy: 'online',
        newCategories: args.categoryName,
        language: args.languageCode
      },
      fetchPolicy: 'cache-and-network'
    });
  }, [fetchCourses]);
  var totalCount = (data === null || data === void 0 ? void 0 : (_data$courses3 = data.courses) === null || _data$courses3 === void 0 ? void 0 : _data$courses3.totalCount) || 0;
  return {
    courses: courses,
    totalCount: totalCount,
    loading: loading,
    error: error,
    fetchCourses: fetchCoursesHandler
  };
};