import { useTranslation } from "@crehana/i18n";
import { mainSectionLocaleEs, mainSectionLocalePt } from "../i18n";
import { HOME_USER_LAST_ACTIVITY_TRANSLATION_KEY } from "../i18n/constants";
var locales = {
  es: mainSectionLocaleEs.es,
  pt: mainSectionLocalePt.pt
};
export var useLearnLastActivityTranslations = function useLearnLastActivityTranslations() {
  var _useTranslation = useTranslation(HOME_USER_LAST_ACTIVITY_TRANSLATION_KEY),
    t = _useTranslation.t,
    languageKey = _useTranslation.languageKey,
    addResourceBundle = _useTranslation.addResourceBundle;
  addResourceBundle(locales);
  return {
    t: t,
    languageKey: languageKey
  };
};