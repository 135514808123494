import { useCallback, useMemo } from 'react';
import * as React from 'react';

import { useQuery } from '@apollo/client';
import cn from 'clsx';

import type { MeObject } from '@crehana/auth';
import Video from '@crehana/icons/outline/Video';
import { Container, Slider } from '@crehana/ui';
import { createUrlWithSourceQueryParams } from '@crehana/utils';

import SectionSeparator from '@/views/LearnDashboard/components/SectionSeparator';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';

import GET_ORGANIZATION_LIVES_QUERY from './graphql/GetOrganizationLives.graphql';
import type {
  GetOrganizationLives,
  GetOrganizationLivesVariables,
} from './graphql/types/GetOrganizationLives';
import useOrganizationLivesTranslations from './hooks/useOrganizationLivesTranslations';
import OrganizationLiveCard from './OrganizationLiveCard';

type OrganizationLivesProps = {
  user: MeObject;
  isDark?: boolean;
};

const OrganizationLives: React.FC<
  React.PropsWithChildren<OrganizationLivesProps>
> = ({ user, isDark, children }) => {
  const { t } = useOrganizationLivesTranslations();
  const { organizationSlug, redirectTo } = useLearnDashboardContext();

  const { data } = useQuery<
    GetOrganizationLives,
    GetOrganizationLivesVariables
  >(GET_ORGANIZATION_LIVES_QUERY, {
    variables: {
      first: 12,
      type: 'old',
      slugOrg: organizationSlug,
    },
  });

  const lives = useMemo(
    () => (data?.livesB2B?.edges.map(el => el?.node!) || []).filter(Boolean),
    [data?.livesB2B?.edges],
  );

  const separatorHandler = useCallback(() => {
    const url = createUrlWithSourceQueryParams({
      url: `/org/${organizationSlug}/live/`,
      source: 'Home Exclusive Broadcasts',
      sourceDetail: 'Text Link Exclusive Broadcasts',
    });

    redirectTo(url, { _blank: true });
  }, [organizationSlug, redirectTo]);

  if (lives.length === 0) {
    return null;
  }

  return (
    <>
      {children}
      <Container type="dashboard">
        <div className="mb-32">
          <SectionSeparator
            icon={Video}
            title={
              <>
                {t('TITLE_2')}&nbsp;
                <span
                  className={cn(
                    'capitalize',
                    isDark ? 'text-secondary-main' : 'text-primary-main',
                  )}
                >
                  {user?.organization?.name}
                </span>
              </>
            }
            ctaAction={separatorHandler}
            ctaLabel={t('SEE_ALL_CTA')}
            isDark={isDark}
            full
            className="mb-20"
          >
            <div className="learn-organization-lives" />
          </SectionSeparator>

          <Slider
            itemsPerPage={{ xs: 1, sm: 3, md: 3, lg: 4 }}
            desktopPaginationSelector=".learn-organization-lives"
            itemsWithShadow
            dark={isDark}
          >
            {lives.map(live => {
              const urlWithSource = createUrlWithSourceQueryParams({
                url: live?.liveDetailUrl!,
                source: 'Home Exclusive Broadcasts',
                sourceDetail: 'Exclusive Broadcasts Cards',
              });

              return (
                <OrganizationLiveCard
                  key={live?.originalId || 0}
                  originalId={live?.originalId}
                  imageUrl={live?.bannerPcImageUrl}
                  title={live?.title}
                  url={urlWithSource}
                  duration={live?.duration}
                  presenter={live?.presenter?.fullName}
                  isDark={isDark}
                  isSubscribed={live?.isSubscribed}
                />
              );
            })}
          </Slider>
        </div>
      </Container>
    </>
  );
};

export default OrganizationLives;
