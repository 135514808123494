import * as React from 'react';

import { cn } from '@crehana/compass.ui/v2';
import { IconProps } from '@crehana/icons/types';
import { Container, TextLink } from '@crehana/ui';

interface SectionSeparatorProps {
  icon: React.FunctionComponent<React.PropsWithChildren<IconProps>>;
  title: string | JSX.Element;
  ctaLabel?: string;
  isDark?: boolean;
  full?: boolean;
  className?: string;
  ctaLink?: string;
  ctaAction?: () => void;
}

const SectionSeparator: React.FC<
  React.PropsWithChildren<SectionSeparatorProps>
> = ({
  icon: Icon,
  title,
  ctaLabel,
  ctaLink,
  ctaAction,
  isDark,
  full,
  className,
  children,
}) => {
  return (
    <Container type="dashboard" full={full} className={className}>
      <div className="tw-flex tw-justify-between tw-w-full">
        <div className="tw-flex tw-items-center">
          <Icon
            size={20}
            ignoreColor
            className={cn('tw-fill-current', !isDark && 'text-base-lighter')}
          />
          <h2 className="font-subtitle2 tw-ml-1.2 tw-flex-shrink-0 tw-line-clamp-1">
            {title}
          </h2>
          {ctaAction && (
            <div
              className={cn(
                'tw-border-l tw-border-solid tw-ml-1.2 tw-pl-1.2',
                isDark ? '' : 'border-gray-main',
              )}
            >
              <TextLink
                onClick={ctaAction}
                href={ctaLink}
                label={ctaLabel}
                isDark={isDark}
              />
            </div>
          )}
        </div>

        {children}
      </div>
    </Container>
  );
};

export default SectionSeparator;
