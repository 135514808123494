import { useCallback } from 'react';
import * as React from 'react';

import dynamic from 'next/dynamic';

import Routes from '@crehana/icons/outline/Routes';
import { SimpleErrorBoundary } from '@crehana/layouts';
import { useMediaQuery } from '@crehana/react-hooks';
import { Container } from '@crehana/ui';
import { createUrlWithSourceQueryParams } from '@crehana/utils';
import type { UserTeamItem } from '@crehana/web';

import SectionSeparator from '@/views/LearnDashboard/components/SectionSeparator';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';
import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks/useCrehanaTranslation';

import { PATH_TRACK } from '../../constants';
import TrackSectionSkeleton from './skeletons/TrackSectionSkeleton';
import type { TTrackSectionProps } from './types';

const TrackSectionDesktop = dynamic(
  () => import('./layouts/TrackSectionDesktop'),
  { ssr: false },
);

const TrackSectionMobile = dynamic(
  () => import('./layouts/TrackSectionMobile'),
  { ssr: false },
);

const TrackSection: React.FC<React.PropsWithChildren<TTrackSectionProps>> = ({
  amplitudeSource,
  amplitudeSourceDetail,
  playroomLearningPathCoins,
  playroomCurrencyName,
  isDark,
  teams,
  loading,
  className,
  children,
}) => {
  const { t } = useCrehanaTranslation();
  const { baseUrlB2B = '', redirectTo, me } = useLearnDashboardContext();
  const { matches: isMd } = useMediaQuery('md');
  const { matches: isSm } = useMediaQuery('sm');
  const isMarketplaceUserHEStudent =
    me?.organization?.myRole === 'high-education-member';

  const redirectToTrack = useCallback(
    (team: UserTeamItem) => {
      let path = `${baseUrlB2B}/my-courses/tracks/?track_id=${
        team.originalId || 0
      }`;

      if (amplitudeSource) {
        path = createUrlWithSourceQueryParams({
          url: path,
          source: amplitudeSource,
          sourceDetail: amplitudeSourceDetail,
        });
      }

      redirectTo(path, {
        state: { team },
      });
    },
    [amplitudeSource, amplitudeSourceDetail, baseUrlB2B, redirectTo],
  );

  const redirectToCourses = useCallback(() => {
    const url = createUrlWithSourceQueryParams({
      url: baseUrlB2B + PATH_TRACK,
      source: 'Home Tracks Section',
      sourceDetail: 'Text Link Tracks',
    });

    redirectTo(url);
  }, [baseUrlB2B, redirectTo]);

  if (!loading && teams.length === 0) {
    return null;
  }

  return (
    <SimpleErrorBoundary>
      {children}
      <div className={className}>
        <SectionSeparator
          icon={Routes}
          isDark={isDark}
          className="mb-20"
          ctaLabel={
            isSm
              ? t('B2B_START_SECTION_GOTO_ROUTES')
              : t('B2B_START_SECTION_GOTO_ROUTES_MOBILE')
          }
          title={t('B2B_START_SECTION_YOUR_LEARNING_PATH')}
          ctaAction={redirectToCourses}
        />

        {loading ? (
          <TrackSectionSkeleton isDark={isDark} />
        ) : (
          <Container type="dashboard">
            {isMd ? (
              <TrackSectionDesktop
                isDark={isDark}
                teams={teams}
                rewardQuantity={playroomLearningPathCoins}
                playroomCurrencyName={playroomCurrencyName}
                onGoToTrack={redirectToTrack}
                isMarketplaceUserHEStudent={isMarketplaceUserHEStudent}
              />
            ) : (
              <TrackSectionMobile
                isDark={isDark}
                teams={teams}
                rewardQuantity={playroomLearningPathCoins}
                playroomCurrencyName={playroomCurrencyName}
                onGoToTrack={redirectToTrack}
                isMarketplaceUserHEStudent={isMarketplaceUserHEStudent}
              />
            )}
          </Container>
        )}
      </div>
    </SimpleErrorBoundary>
  );
};

export default TrackSection;
