import { useTranslation } from '@crehana/i18n';
import type { TSetTranslationKeys } from '@crehana/ts-types';

import { SUPPORT_SECTION_TRANSLATION_KEY } from '../constants';
import locales, { type localeEs } from '../i18n';

export type CrehanaBlogSectionLocaleKeys = TSetTranslationKeys<typeof localeEs>;

const useSupportTranslation = () => {
  const { addResourceBundle, ...translationContextValue } =
    useTranslation<CrehanaBlogSectionLocaleKeys>(
      SUPPORT_SECTION_TRANSLATION_KEY,
    );

  addResourceBundle(locales);

  return translationContextValue;
};

export default useSupportTranslation;
