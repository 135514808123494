import { FC } from 'react';

import StartSection from './StartSection';
import StartSectionNew from './StartSectionNew';
import type { StartSectionProps } from './types';
import { withProviders } from './withProviders';

const LazyStartSection: FC<StartSectionProps> = props => {
  return props.canNewSectionLearning ? (
    <StartSectionNew {...props} />
  ) : (
    <StartSection {...props} />
  );
};

export default withProviders(LazyStartSection);
