import { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useRouter } from 'next/router';

import { createUrlWithSourceQueryParams } from '@crehana/analytics';
import { useMe } from '@crehana/auth';
import {
  URL_RIVE_ANIMATIONS_ASSET_CONFETTI_URL,
  useIsPlayRoomActivated,
} from '@crehana/compass.playroom';
import { UIThemeProvider } from '@crehana/compass.ui';
import { PageLoader } from '@crehana/compass.ui/v2';
import { FeatureFlagProvider } from '@crehana/feature-flags/react';
import { useTranslation } from '@crehana/i18n';
import { CrehanaNextSeo, type TAppProps } from '@crehana/next';
import { PrivatePage, useUITheme } from '@crehana/web';

import { B2BRootLayout } from './components/B2BOrgLayout';
import IntercomProviderHooks from './components/LoadChatScripts/IntercomProviderHooks';
import SkillXPEvolutionReminder from './components/SkillXPEvolutionReminder';
import { LEARN_DASHBOARD_PAGE_NAME } from './constants';
import { LearnDashboardProvider } from './context';
import useGetPendingDashboardSkillXPEvolution from './layouts/b2b/SkillXP-Evolution/sections/PendingSkills/hooks/useGetPendingDashboardSkillXPEvolution';
import LearnDashboard from './main/LearnDashboard';
import { SKILLXP_ROUTE } from './routes/common.routes';
import type { IAppProps } from './types';
import { changeFavicon } from './utils/changeFavicon';

const App: FC<TAppProps<IAppProps>> = ({
  organizationInfo,
  organizationSlug,
  isSidebarV2Enabled,
}) => {
  const router = useRouter();
  const { me, loading } = useMe();
  const { isDark } = useUITheme();
  const { languageCode } = useTranslation();
  const {
    isActivated: isPlayroomEnabled,
    loading: isLoadingPlayroomActivated,
  } = useIsPlayRoomActivated(organizationSlug);

  const isB2B = !!me?.isB2B;
  // Don't replace this constant with organizationSlug prop because it's not always available, and it's not same as organizationInfo.slug
  const userOrganizationSlug = me?.organization?.slug;
  const userOrganizationId = me?.organization?.originalId || undefined;

  const shouldFixCurrentB2BUrl =
    isB2B &&
    !!userOrganizationSlug &&
    !!organizationSlug &&
    userOrganizationSlug !== organizationSlug;
  const isLoggedIn = !loading && !!me;
  const shouldRenderContent =
    isLoggedIn && languageCode && !shouldFixCurrentB2BUrl;

  const { loading: loadingTests, pendingTests } =
    useGetPendingDashboardSkillXPEvolution(me?.userOrganization?.originalId!);

  /**
   * If the user is logged in and the organization slug is not the same as the one in the URL,
   * redirect to the correct organization.
   */
  useEffect(() => {
    if (shouldFixCurrentB2BUrl) {
      void router.replace(`/org/${userOrganizationSlug}/learn/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFixCurrentB2BUrl, userOrganizationSlug]);

  /**
   * If the user is not logged in, redirect to the login page.
   */
  useEffect(() => {
    if (!loading && !me) {
      if (organizationSlug) {
        const b2bLoginUrl = `/org/${organizationSlug}/entrar/?next=${router.asPath}`;

        void router.replace(b2bLoginUrl);
      } else {
        const loginUrl = `/entrar/?next=${router.asPath}`;

        void router.replace(loginUrl);
      }
    } else if (me && !me.isB2B) {
      // If the user is logged in but is not a B2B, redirect to the B2C Home User.
      const homeB2C_URL = createUrlWithSourceQueryParams({
        url: `/home/`,
        source: LEARN_DASHBOARD_PAGE_NAME,
        sourceDetail: 'Auto Redirect',
      });

      void router.replace(homeB2C_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, me, organizationSlug]);

  const renderPageContent = () => {
    if (!shouldRenderContent) {
      return null;
    }

    const organizationTheme = {
      primaryColor: organizationInfo?.lookAndFeel?.primary?.main || undefined,
      secondaryColor:
        organizationInfo?.lookAndFeel?.secondary?.main || undefined,
    };

    return (
      <LearnDashboardProvider
        me={me}
        isDark={isDark}
        organizationSlug={organizationSlug}
        organizationId={userOrganizationId}
        organizationTheme={organizationTheme}
      >
        <IntercomProviderHooks organizationInfo={organizationInfo}>
          <LearnDashboard
            isPlayroomEnabled={isPlayroomEnabled}
            isSidebarV2Enabled={isSidebarV2Enabled}
            isLoadingPlayroomActivated={isLoadingPlayroomActivated}
            organizationInfo={organizationInfo}
          />
          {!loadingTests && (
            <SkillXPEvolutionReminder
              open={pendingTests?.length > 0}
              total={pendingTests.length}
              onRedirect={() => router.replace(SKILLXP_ROUTE.path)}
            />
          )}
        </IntercomProviderHooks>
      </LearnDashboardProvider>
    );
  };

  useEffect(() => {
    if (organizationInfo?.favicon) {
      changeFavicon(organizationInfo.favicon);
    }
  }, [organizationInfo]);

  return (
    <>
      <CrehanaNextSeo
        title="Learn Dashboard"
        description="Certifícate, alcanza tus objetivos y mejora tus habilidades con nuestros packs de cursos con más de 40% de descuento. ¡Aprende hoy y aplica hoy!"
        hasCountryCode={false}
        nofollow
        noindex
        additionalLinkTags={[
          {
            rel: 'preload',
            href: URL_RIVE_ANIMATIONS_ASSET_CONFETTI_URL,
          },
        ]}
      />

      <PrivatePage
        isDark={isDark}
        languageCode={languageCode}
        organizationSlug={organizationSlug}
      >
        <B2BRootLayout
          isB2B={isB2B}
          organizationSlug={organizationSlug}
          organizationInfo={organizationInfo}
        />

        {renderPageContent()}
      </PrivatePage>
    </>
  );
};

const withProviders = (Component: FC<TAppProps<IAppProps>>) => {
  const Providers = (props: TAppProps<IAppProps>) => {
    const { me, loading } = useMe();
    const { languageCode } = useTranslation();

    if (loading) {
      return <PageLoader size="s" languageCode={languageCode} />;
    }

    const userOrganizationId = me?.organization?.originalId || undefined;

    return (
      <UIThemeProvider>
        <BrowserRouter>
          <FeatureFlagProvider
            flags={[
              ['user_notification_web', 'V2'],
              ['CERTIFICATION_WITH_QUIZZES', 'V5'],
              ['API_V5_CONTENT_DISCOVERY', 'V5'],
              ['LIVE_SESSIONS_STAGE_1', 'V5'],
              ['CONTENT_TRACK_REDESIGN_COLLABORATOR', 'V5'],
            ]}
            organizationId={userOrganizationId!}
          >
            <Component {...props} />
          </FeatureFlagProvider>
        </BrowserRouter>
      </UIThemeProvider>
    );
  };

  Providers.displayName = 'App';

  return Providers;
};

export default withProviders(App);
