import { ApprovalRequirementEnum, CourseTypeEnum } from "../../__generated__/graphql-schema";
export function isCourseElevate(course) {
  return course.courseTypeEnum === CourseTypeEnum.COURSE_TYPE_B2B_CLASSROOMS;
}
export var isCourseCanUploadProject = function isCourseCanUploadProject(course) {
  var _course$permissionsV;
  return Boolean((_course$permissionsV = course.permissionsV2) === null || _course$permissionsV === void 0 ? void 0 : _course$permissionsV.canUploadProject);
};
export var isCourseWithQuiz = function isCourseWithQuiz(course) {
  var _course$studioConfigS;
  return ((_course$studioConfigS = course.studioConfigSet) === null || _course$studioConfigS === void 0 ? void 0 : _course$studioConfigS.approvalRequirementEnum) === ApprovalRequirementEnum.QUIZ_EVALUATION;
};