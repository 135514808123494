export default {
  es: {
    HU_B2B_ACTIONS_TITLE: 'Tenemos más para ti...',
    HU_B2B_ACTIONS_DESCRIPTION: 'Tu administrador te asignará una ruta de aprendizaje. Mientras tanto explora los siguientes contenidos que tenemos para ti.',
    HU_B2B_START_TEST_SKILL_XP: 'Realiza tu test de Skill XP',
    HU_B2B_START_TEST_SKILL_XP_CTA: 'Empezar Skill XP',
    HU_B2B_MENTORS: 'Consulta tus dudas con nuestros mentores',
    HU_B2B_MENTORS_CTA: 'Conocer Mentores',
    HU_B2B_BLOG: 'Revisa nuestro material complementario',
    HU_B2B_BLOG_CTA: 'Ir al blog',
    HU_B2B_SUPPORT: '¿Dudas? Estamos aquí para ayudarte',
    HU_B2B_SUPPORT_CTA: 'Ir al Centro de Ayuda'
  }
};