import * as React from 'react';

import LevelCompleteVertical from '@crehana/icons/LevelCompleteVertical';
import LevelEsencialVertical from '@crehana/icons/LevelEsencialVertical';
import LevelIntermediateVertical from '@crehana/icons/LevelIntermediateVertical';
import { colors } from '@crehana/ui';

import { levelType } from '../types';

const { baseLighter } = colors;

type TOrgCourseLevelIconProps = Pick<levelType, 'originalId'>;

const OrgCourseLevelIcon: React.FC<
  React.PropsWithChildren<TOrgCourseLevelIconProps>
> = ({ originalId }) => {
  if (originalId === 1) {
    return (
      <LevelEsencialVertical color={baseLighter} size={24} className="mr-8" />
    );
  }
  if (originalId === 2) {
    return (
      <LevelIntermediateVertical
        color={baseLighter}
        size={24}
        className="mr-8"
      />
    );
  }
  if (originalId === 3) {
    return (
      <LevelCompleteVertical color={baseLighter} size={24} className="mr-8" />
    );
  }
  if (originalId === 4) {
    return (
      <LevelCompleteVertical color={baseLighter} size={24} className="mr-8" />
    );
  }

  return null;
};

export default OrgCourseLevelIcon;
