import { useCallback, useLayoutEffect, useState } from 'react';

import { clsx } from 'clsx';

import {
  getCommonAmplitudeProperties,
  getSourceEventParams,
  useAmplitude,
  type AmplitudeProps,
} from '@crehana/analytics';
import { StatusSessionEnum } from '@crehana/compass.live-sessions';
import { useLiveSessions } from '@crehana/compass.live-sessions/queries';
import {
  useAvailableCurrencyByUser,
  usePlayroomCoinEventValues,
} from '@crehana/compass.playroom';
import { useFeatureFlag } from '@crehana/feature-flags/react';
import { useMediaQuery } from '@crehana/react-hooks';
import {
  MainFooter,
  useActionMetrics,
  useLearnLastActivityContext,
  useNewCoursesQuery,
  useStudyPlan,
  type StudyPlanActionName,
} from '@crehana/web';

import { useCourseEnrollments } from '@/shared/components/Courses/hooks';
import { TrackSectionScroll } from '@/views/LearnDashboard/components';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';
import useHideHigherEducationHomeUserUI from '@/views/LearnDashboard/hooks/useHigherEducationHomeUserUI';
import useGetTeamTracksMigration from '@/views/LearnDashboard/hooks/useTeamTracks/useGetTeamTracksMigration';

import CatalogLayout from './components/CatalogLayout';
import LastActivity from './components/LastActivity';
import LearningPlanLayout from './components/LearningPlanLayout';
import {
  AMPLITUDE_PLAYROOM_REWARD_BANNER_CTA_SOURCE,
  AMPLITUDE_PLAYROOM_REWARD_BANNER_CTA_SOURCE_DETAIL,
  AMPLITUDE_SCROLL_EVENT_NAME,
} from './constants';
import useActionsURL from './hooks/useActionsURL';
import useGetCanUseInduction from './hooks/useGetCanUseInduction';
import useGetCourseEnrollment from './hooks/useGetCourseEnrollment';
import useGetKnowledgeHubConfig from './hooks/useGetKnowledgeHubConfig';
import { useNextLiveSession } from './hooks/useNextLiveSession';
import useGetCoursesWithoutTrack from './sections/CoursesThatDontBelongToTracks/hooks/useGetCoursesWithoutTrack';
import TrackCourseHandler from './sections/CoursesThatDontBelongToTracks/TrackCourseHandler';
import RewardsBanner from './sections/RewardsBanner/RewardsBanner';
import RewardsDialog from './sections/RewardsDialog';
import SkeletonStartSection from './sections/SkeletonStartSection';
import Tasks from './sections/Tasks/Tasks';
import type { StartSectionProps } from './types';

const StartSectionNew: React.FC<StartSectionProps> = ({ me, isDark }) => {
  const { logEvent } = useAmplitude();
  const { newCourses, loading: loadingCourses } = useNewCoursesQuery({
    first: 5,
  });
  const { courseSlide, loadingCourseEnrollmentProgress } =
    useGetCourseEnrollment();
  const {
    organizationSlug,
    isPlayroomEnabled,
    isLoadingPlayroomActivated,
    redirectTo,
  } = useLearnDashboardContext();
  const isB2BOnlyPanel = Boolean(me.isB2BOnlyPanel);
  const organizationId = String(me.organization?.originalId);
  const { matches: isMd } = useMediaQuery('md');
  const [isRewardsDialogOpen, setIsRewardsDialogOpen] =
    useState<boolean>(false);
  const { meRole } = useGetCanUseInduction();
  const { hasEnrollments } = useLearnLastActivityContext();
  const { getActionClickLearningPlan } = useActionsURL();
  const { hasCurrency } = useAvailableCurrencyByUser(
    organizationSlug as string,
  );
  const { playroomLearningPathCoins, playroomCourseCoins } =
    usePlayroomCoinEventValues(organizationSlug as string, organizationId);
  const { knowledgeHubActivated } = useGetKnowledgeHubConfig({
    organizationId,
  });

  const { coursesWithoutTrack, loading: loadingCourseEnrollment } =
    useGetCoursesWithoutTrack();

  const {
    teams,
    totalCountTeams,
    loading: loadingTracks,
  } = useGetTeamTracksMigration({
    organization_id: Number(organizationId),
    organization_slug: organizationSlug || '',
  });

  const { totalEnrollmentsCount, loading: loadingEnrollments } =
    useCourseEnrollments({
      lastActions: true,
      // We only want to get total enrollments count (totalEnrollmentsCount) for the current user.
      // 'totalEnrollmentsCount' is independent of the 'first' variable.
      first: 1,
    });
  const { plan, loading: loadingPlan } = useStudyPlan();

  const { loading: loadingLiveSessionData, sessions } = useLiveSessions(
    Number(organizationId),
    StatusSessionEnum.NEXT,
  );

  const nextLiveSession = useNextLiveSession({
    sessions,
    loading: loadingLiveSessionData,
  });

  const showMarketplaceUIFF = useHideHigherEducationHomeUserUI();
  const { flagValue: activatedFlag, loading: loadingFF } = useFeatureFlag(
    'CERTIFICATION_WITH_QUIZZES',
  );
  const { flagValue: isLiveSessionFF, loading: loadingFFLiveSessions } =
    useFeatureFlag('LIVE_SESSIONS_STAGE_1');
  const isMarketplaceUser =
    me?.organization?.myRole === 'high-education-member';
  const isMarketplaceUserAndUIFF = isMarketplaceUser && showMarketplaceUIFF;
  const shouldRenderCoursesThatDontBelongToTracks = !isMarketplaceUserAndUIFF;
  const shouldRenderKnowledgeHubCatalog =
    !isB2BOnlyPanel && !isMarketplaceUserAndUIFF && knowledgeHubActivated;

  const COINS_COURSE: number | undefined =
    playroomCourseCoins !== 0 ? playroomCourseCoins : undefined;

  const onActionClickLearningPlan = useCallback(
    (
      actionName: StudyPlanActionName,
      amplitude?: Partial<AmplitudeProps>,
    ): void => {
      const urlWithSource = getActionClickLearningPlan(actionName, amplitude);

      redirectTo(urlWithSource);
    },
    [getActionClickLearningPlan, redirectTo],
  );

  const renderTrackCourseHandler = () => {
    return (
      <TrackCourseHandler
        containerType="dashboard"
        teams={teams}
        totalCountTeams={totalCountTeams}
        courses={coursesWithoutTrack}
        liveSessionData={sessions}
        playroomCourseCoins={COINS_COURSE}
        playroomTrackCoins={
          playroomLearningPathCoins !== 0
            ? playroomLearningPathCoins
            : undefined
        }
        knowledgeHubActivated={knowledgeHubActivated}
        isFeatureFlagActive={isLiveSessionFF}
      >
        <TrackSectionScroll
          amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
          amplitudeSource="Assigned Courses Section"
        />
      </TrackCourseHandler>
    );
  };

  const { loading: loadingMetrics, actionMetricProps } = useActionMetrics({
    onActionClick: onActionClickLearningPlan,
  });

  const shouldRenderLoading =
    loadingCourses ||
    loadingPlan ||
    loadingMetrics ||
    loadingCourseEnrollment ||
    loadingTracks ||
    loadingFFLiveSessions ||
    loadingLiveSessionData ||
    loadingCourseEnrollmentProgress;

  useLayoutEffect(() => {
    const isReadyToSentAmplitudeEventRender =
      !isLoadingPlayroomActivated &&
      !loadingTracks &&
      !loadingEnrollments &&
      !loadingFF;

    if (isReadyToSentAmplitudeEventRender) {
      const amplitudeParameters: Readonly<Record<string, string | number>> = {
        ...getSourceEventParams(),
        ...getCommonAmplitudeProperties(me, isPlayroomEnabled),
        'Learning Path Count': teams.length,
        'Courses Enrolled': totalEnrollmentsCount > 0 ? 'Yes' : 'No',
        'Courses Enrolled Count': totalEnrollmentsCount,
        'Quiz Certification Available': activatedFlag ? 'Yes' : 'No',
        'Tracks Enrolled': teams.find(items => {
          if (
            items.coursesSummary?.inProgressCourses &&
            items.coursesSummary?.inProgressCourses > 0
          )
            return items;
          return undefined;
        })
          ? 'Yes'
          : 'No',
        'Study Plan Defined': plan ? 'Yes' : 'No',
      };

      logEvent({
        event: 'Render HomeUser Start',
        data: amplitudeParameters,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingPlayroomActivated,
    isPlayroomEnabled,
    loadingEnrollments,
    loadingTracks,
    teams.length,
    totalEnrollmentsCount,
    loadingFF,
    me,
  ]);

  if (shouldRenderLoading) return <SkeletonStartSection />;

  return (
    <section className="w-full h-full">
      {!isMarketplaceUserAndUIFF && (
        <div className="tw-overflow-hidden">
          <RewardsBanner
            isDark={isDark}
            isPlayroomEnabled={isPlayroomEnabled}
            hasCurrency={hasCurrency}
            amplitudeSource={AMPLITUDE_PLAYROOM_REWARD_BANNER_CTA_SOURCE}
            amplitudeSourceDetail={
              AMPLITUDE_PLAYROOM_REWARD_BANNER_CTA_SOURCE_DETAIL
            }
          />
        </div>
      )}
      <LastActivity
        isMarketplaceUserAndUIFF={isMarketplaceUserAndUIFF}
        onActionClickLearningPlan={onActionClickLearningPlan}
        isDark={isDark}
        newCourses={newCourses}
        plans={plan}
        actionMetrics={actionMetricProps}
        isCourseAssigned={coursesWithoutTrack.length > 0}
        coinsCourse={COINS_COURSE}
        isActiveFF={isLiveSessionFF}
        nextLiveSession={nextLiveSession}
        courseProgress={courseSlide}
      />

      <RewardsDialog
        open={isRewardsDialogOpen}
        onDismiss={() => setIsRewardsDialogOpen(false)}
      />

      {meRole === 'induction' ? (
        <Tasks
          slug={organizationSlug as string}
          userId={String(me.originalId)}
        />
      ) : (
        <>
          {isMarketplaceUserAndUIFF && !isMd && (
            <LearningPlanLayout
              onActionClickLearningPlan={onActionClickLearningPlan}
              isDark={isDark}
              isCourseAssigned={coursesWithoutTrack.length > 0}
            />
          )}

          {hasEnrollments ? (
            <>
              {shouldRenderCoursesThatDontBelongToTracks &&
                renderTrackCourseHandler()}
            </>
          ) : (
            <>
              {shouldRenderKnowledgeHubCatalog && <CatalogLayout />}

              {shouldRenderCoursesThatDontBelongToTracks &&
                !isB2BOnlyPanel &&
                renderTrackCourseHandler()}
            </>
          )}
        </>
      )}

      <TrackSectionScroll
        amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
        amplitudeSource="Footer Section"
      />
      <MainFooter
        isDark={isDark}
        ignoreBgTransparent
        className={clsx(!isDark && 'bg-white')}
        containerType="dashboard"
        layouts={{
          shouldHideWeAreHiringLink: true,
          shouldHideSignUpLink: true,
          shouldHideResourceSection: true,
        }}
      />
    </section>
  );
};

export default StartSectionNew;
