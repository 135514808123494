import { B2B_ORGANIZATION_LIVES_KEY } from '../constants';

const localePT = {
  [B2B_ORGANIZATION_LIVES_KEY]: {
    TITLE: 'Sessões exclusivas para você',
    TITLE_2: 'Transmissões exclusivas da',
    SEE_ALL_CTA: 'Ver todos',
    CARD_DURATION: `Duração: {{duration}}`,
    CARD_PRESENTED_BY: `Por: {{presenter}}`,
    CARD_CTA_LABEL: 'Ver sessão',
  },
};

export default localePT;
