import { SUPPORT_CARD_TRANSLATION_KEY } from '../constants';

export default {
  en: {
    [SUPPORT_CARD_TRANSLATION_KEY]: {
      SUPPORT_CARD_CALENDLY_TITLE: 'Schedule a Welcome Call',
      SUPPORT_CARD_CALENDLY_DESCRIPTION:
        'If you need help with your membership, schedule a support call with a Customer Happiness agent through Calendly.',
      SUPPORT_CARD_CALENDLY_DESCRIPTION_FIT:
        'Schedule an exclusive support call just for you whenever you want.',
      SUPPORT_CARD_CALENDLY_CTA: 'Schedule Video Call',
      SUPPORT_CARD_ACADEMIC_ADVISING_TITLE: 'Academic Advising',
      SUPPORT_CARD_ACADEMIC_ADVISING_DESCRIPTION_FIT:
        'Do you want to receive periodic advising sessions?',
      SUPPORT_CARD_ACADEMIC_ADVISING_DESCRIPTION:
        'Our call center agents will contact you soon to assist you with your curriculum, learning paths, and achieving your goals. For this, we will need you to confirm your phone number.',
      SUPPORT_CARD_HELP_CENTER_TITLE: 'Help Center',
      SUPPORT_CARD_HELP_CENTER_DESCRIPTION:
        'Our Customer Happiness team has prepared a series of help articles with everything you need to start learning.',
      SUPPORT_CARD_HELP_CENTER_DESCRIPTION_FIT:
        'Check if your question is answered in the frequently asked questions.',
      SUPPORT_CARD_HELP_CENTER_CTA: 'Go to Help Center',

      SUPPORT_CARD_PHONE_EDITOR_LABEL: 'Phone Number',
      SUPPORT_CARD_PHONE_EDITOR_DESCRIPTION: "Don't worry, we won't publish it",
      SUPPORT_CARD_PHONE_EDITOR_CONFIRM_NUMBER: 'Confirm Number',
      SUPPORT_CARD_PHONE_EDITOR_ADVISING_REQUIRED_NUMBER:
        '* You must provide your contact number',
      SUPPORT_CARD_PHONE_EDITOR_CTA_LABEL: 'Edit My Number',
      SUPPORT_CARD_PHONE_UPDATE_CTA_LABEL: 'Update Your Number',
    },
  },
};
