import { useMemo } from 'react';

import type { MeObject } from '@crehana/auth';
import { ILearningMenuLink, useB2bRoutes } from '@crehana/compass.layouts';
import { useIsBrazil, type CrehanaTFunction } from '@crehana/i18n';
import type { NestedRouteType, RouteType, SingleRouteType } from '@crehana/web';

import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks/useCrehanaTranslation';
import {
  premiumRoutes,
  type CustomRouteType,
} from '@/views/LearnDashboard/routes/premium.routes';
import type { LearnDashboardLocaleKeys } from '@/views/LearnDashboard/types';

import useRoutesExtraData from './useRoutesExtraData';

interface UseRouteArgs {
  me?: MeObject | null;
  organizationSlug?: string;
  isSidebarV2Enabled?: boolean;
}

const translateI18nRoutes = (
  t: CrehanaTFunction<LearnDashboardLocaleKeys>,
  routes: RouteType[] | SingleRouteType[],
) => {
  return routes.map(route => {
    if ((route as NestedRouteType).nested?.length) {
      (route as NestedRouteType).nested = translateI18nRoutes(
        t,
        (route as NestedRouteType).nested!,
      ) as SingleRouteType[];
    }

    if (route.label) {
      route.label = t(route.label as LearnDashboardLocaleKeys);
    }

    if ((route as SingleRouteType).contentTitle) {
      (route as SingleRouteType).contentTitle = t(
        (route as SingleRouteType).contentTitle as LearnDashboardLocaleKeys,
      );
    }

    if ((route as SingleRouteType).decorations?.subTitle) {
      (route as SingleRouteType).decorations!.subTitle = t(
        (route as SingleRouteType).decorations!
          .subTitle as LearnDashboardLocaleKeys,
      );
    }

    return route;
  });
};

interface UseRoutesReturnType {
  routes: RouteType[];
  defaultRoute?: SingleRouteType;
  isLoading: boolean;
  isPlayroomEnabled: boolean;
  isKnowledgeHubEnabled: boolean;
}

const useLearnDashboardRoutes = ({
  me,
  organizationSlug,
  isSidebarV2Enabled = false,
}: UseRouteArgs): UseRoutesReturnType => {
  const { t } = useCrehanaTranslation();

  const { routesExtraData } = useRoutesExtraData({
    isSidebarV2Enabled,
  });
  const isBrazil = useIsBrazil();

  const isPremium = !!me?.isPremium;
  const isB2B = !!me?.isB2B;
  const {
    b2bRoutes: preFormattedB2BRoutes,
    isLoading,
    isPlayroomEnabled,
    isKnowledgeHubEnabled,
  } = useB2bRoutes({
    organizationSlug: organizationSlug || '',
    productPlatform: 'learning',
    email: me?.email || '',
    routesExtraData,
  });

  const b2bRoutes = mapRoutesToRoutesType(preFormattedB2BRoutes);

  const [routes, defaultRoute] = useMemo<
    [RouteType[], SingleRouteType | undefined]
  >(() => {
    if (isB2B && organizationSlug) {
      return [b2bRoutes, b2bRoutes[0] as SingleRouteType];
    }

    let premiumRoutesTranslated: CustomRouteType[] = translateI18nRoutes(
      t,
      premiumRoutes,
    );

    premiumRoutesTranslated = premiumRoutesTranslated.filter(
      route =>
        !(isBrazil && route.hiddenAccessByRegion?.isBrazil) &&
        ((isB2B && route.canAccessByRole?.isB2B) ||
          (isPremium && route.canAccessByRole?.isPremium)),
    );

    return [
      premiumRoutesTranslated,
      premiumRoutesTranslated[0] as SingleRouteType,
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isB2B, organizationSlug, isPremium, b2bRoutes]);

  return {
    routes,
    defaultRoute,
    isLoading,
    isPlayroomEnabled,
    isKnowledgeHubEnabled,
  };
};

export default useLearnDashboardRoutes;

const mapRoutesToRoutesType = (
  learningLinks: ILearningMenuLink[],
): RouteType[] => {
  return learningLinks.map(link => {
    if (link.nested) {
      return {
        ...link,
        key: link.code,
        nested: link.nested.map(
          nestedLink =>
            ({
              ...nestedLink,
              path: nestedLink.url,
              key: nestedLink.code,
              exact: nestedLink.exact,
            } as SingleRouteType),
        ),
      } as NestedRouteType;
    }
    return {
      ...link,
      key: link.code,
      path: link.url || '',
      exact: !!link.exact,
    } as SingleRouteType;
  });
};
