import { useCallback, useMemo } from 'react';
import * as React from 'react';

import { useQuery } from '@apollo/client';
import cn from 'clsx';

import type { MeObject } from '@crehana/auth';
import Grid from '@crehana/icons/outline/Grid';
import { Container, ContainerProps, Slider } from '@crehana/ui';
import { createUrlWithSourceQueryParams } from '@crehana/utils';

import SectionSeparator from '@/views/LearnDashboard/components/SectionSeparator';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';

import OrganizationCourseCard from './components/OrganizationCourseCard';
import GET_ORGANIZATION_COURSES_QUERY from './graphql/GetClassroomEnrollmentSet.graphql';
import type {
  GetOrganizationEnrollmentSet,
  GetOrganizationEnrollmentSetVariables,
} from './graphql/types/GetOrganizationEnrollmentSet';
import useOrganizationCoursesTranslation from './hooks/useOrganizationCoursesTranslation';
import modifyOrgCourseUrlsWithAmplitude from './utils/modifyOrgCourseUrlsWithAmplitude';

type OrganizationLivesProps = {
  slug: string;
  user: MeObject;
  isDark?: boolean;
  containerType?: ContainerProps['type'];
  amplitudeSource: string;
  amplitudeSourceDetail?: string;
  separatorAmplitudeSourceDetail?: string;
  playroomCourseCoins?: number;
};

const OrganizationCourses: React.FC<
  React.PropsWithChildren<OrganizationLivesProps>
> = ({
  slug,
  user,
  isDark,
  containerType,
  amplitudeSource,
  playroomCourseCoins,
  amplitudeSourceDetail,
  separatorAmplitudeSourceDetail,
  children,
}) => {
  const { baseUrlB2B, redirectTo } = useLearnDashboardContext();
  const { t } = useOrganizationCoursesTranslation();

  const { data } = useQuery<
    GetOrganizationEnrollmentSet,
    GetOrganizationEnrollmentSetVariables
  >(GET_ORGANIZATION_COURSES_QUERY, {
    variables: { first: 20, organizationSlug: slug },
    skip: !slug,
    fetchPolicy: 'no-cache',
  });

  const courses = useMemo(
    () =>
      (
        data?.me?.classroomEnrollmentSet?.edges.map(el => el?.node?.course!) ||
        []
      ).map(
        modifyOrgCourseUrlsWithAmplitude(
          amplitudeSource,
          amplitudeSourceDetail,
        ),
      ),
    [
      amplitudeSource,
      amplitudeSourceDetail,
      data?.me?.classroomEnrollmentSet?.edges,
    ],
  );

  const ctaActionHandler = useCallback(() => {
    let url = `${baseUrlB2B}/my-courses/enrolled/`;

    if (amplitudeSource) {
      url = createUrlWithSourceQueryParams({
        url,
        source: amplitudeSource,
        sourceDetail: separatorAmplitudeSourceDetail,
      });
    }

    redirectTo(url);
  }, [baseUrlB2B, redirectTo, amplitudeSource, separatorAmplitudeSourceDetail]);

  if (courses.length === 0) {
    return null;
  }

  return (
    <>
      {children}
      <Container type={containerType}>
        <SectionSeparator
          isDark={isDark}
          title={
            <>
              {t('TITLE')}{' '}
              <span
                className={cn(
                  'capitalize',
                  isDark ? 'text-secondary-main' : 'text-primary-main',
                )}
              >
                {user?.organization?.name}&nbsp;
              </span>
              {t('TITLE_2')}
            </>
          }
          ctaLabel={t('SEE_ALL_CTA')}
          ctaAction={ctaActionHandler}
          icon={Grid}
          full
          className="mb-16"
        >
          <div className="organization-courses" />
        </SectionSeparator>

        <div className="mb-32 sm:mb-56">
          <Slider
            itemsPerPage={{ xs: 1, sm: 3, md: 3, lg: 4 }}
            desktopPaginationSelector=".organization-courses"
            itemsWithShadow
            dark={isDark}
          >
            {courses.map(course => (
              <OrganizationCourseCard
                key={course.originalId}
                promoImage={course?.promoImage || ''}
                title={course?.title || ''}
                url={course?.dashboardUrl || ''}
                duration={course?.duration || 0}
                progress={course?.progress}
                isDark={isDark}
                level={course?.level}
                playroomCourseCoins={playroomCourseCoins}
              />
            ))}
          </Slider>
        </div>
      </Container>
    </>
  );
};

export default OrganizationCourses;
