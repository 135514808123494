import { B2B_ORGANIZATION_LIVES_KEY } from '../constants';

const en = {
  [B2B_ORGANIZATION_LIVES_KEY]: {
    TITLE: 'Exclusive sessions for you',
    TITLE_2: 'Exclusive broadcasts from',
    SEE_ALL_CTA: 'See all',
    CARD_DURATION: `Duration: {{duration}}`,
    CARD_PRESENTED_BY: `By: {{presenter}}`,
    CARD_CTA_LABEL: 'View session',
  },
};

export default en;
