import { SUPPORT_CARD_TRANSLATION_KEY } from '../constants';

export default {
  es: {
    [SUPPORT_CARD_TRANSLATION_KEY]: {
      SUPPORT_CARD_CALENDLY_TITLE: 'Agenda una llamada de bienvenida',
      SUPPORT_CARD_CALENDLY_DESCRIPTION:
        'Si necesitas ayuda sobre tu membresía, agenda una llamada de soporte con un agente de Customer Happiness a través de Calendly.',
      SUPPORT_CARD_CALENDLY_DESCRIPTION_FIT:
        'Agenda una llamada de soporte exclusiva para ti cuando quieras.',
      SUPPORT_CARD_CALENDLY_CTA: 'Agenda videollamada',
      SUPPORT_CARD_ACADEMIC_ADVISING_TITLE: 'Asesoría académica',
      SUPPORT_CARD_ACADEMIC_ADVISING_DESCRIPTION_FIT:
        '¿Deseas recibir asesorías cada cierto tiempo?',
      SUPPORT_CARD_ACADEMIC_ADVISING_DESCRIPTION:
        'Nuestros agentes de call center se contactarán contigo pronto para ayudarte con tu plan de estudios, rutas de aprendizaje y como lograr tus objetivos. Para esto, necesitaremos que nos confirmes tu número de teléfono.',
      SUPPORT_CARD_HELP_CENTER_TITLE: 'Centro de ayuda',
      SUPPORT_CARD_HELP_CENTER_DESCRIPTION:
        'Nuestro equipo de Customer Happiness ha preparado una serie de artículos de ayuda con todo lo necesario para empezar a aprender.',
      SUPPORT_CARD_HELP_CENTER_DESCRIPTION_FIT:
        'Revisa si tu duda está resuelta en preguntas frecuentes.',
      SUPPORT_CARD_HELP_CENTER_CTA: 'Ve al Centro de Ayuda',

      SUPPORT_CARD_PHONE_EDITOR_LABEL: 'Número de teléfono',
      SUPPORT_CARD_PHONE_EDITOR_DESCRIPTION: 'Descuida que no lo publicaremos',
      SUPPORT_CARD_PHONE_EDITOR_CONFIRM_NUMBER: 'Confirmar número',
      SUPPORT_CARD_PHONE_EDITOR_ADVISING_REQUIRED_NUMBER:
        '* Debes proporcionar tu número de contacto',
      SUPPORT_CARD_PHONE_EDITOR_CTA_LABEL: 'Editar mi número',
      SUPPORT_CARD_PHONE_UPDATE_CTA_LABEL: 'Actualiza tu número',
    },
  },
};
