import { useCallback, useLayoutEffect, useState } from 'react';

import cn from 'clsx';

import {
  getCommonAmplitudeProperties,
  getSourceEventParams,
  useAmplitude,
  type AmplitudeProps,
} from '@crehana/analytics';
import {
  useAvailableCurrencyByUser,
  usePlayroomCoinEventValues,
} from '@crehana/compass.playroom';
import { useFeatureFlag } from '@crehana/feature-flags/react';
import PlaySquare from '@crehana/icons/outline/PlaySquare';
import { useMediaQuery } from '@crehana/react-hooks';
import type { CountryCode } from '@crehana/ts-types';
import { Container } from '@crehana/ui';
import {
  CatalogEmptyState,
  LearningPlan,
  LearnLastActivity,
  MainFooter,
  useLearnLastActivityContext,
  useStudyPlan,
  type StudyPlanActionName,
} from '@crehana/web';

import { useCourseEnrollments } from '@/shared/components/Courses/hooks';
import {
  MainTitle,
  TrackSectionScroll,
} from '@/views/LearnDashboard/components';
import SectionSeparator from '@/views/LearnDashboard/components/SectionSeparator';
import Support from '@/views/LearnDashboard/components/Support';
import { LEARN_DASHBOARD_PAGE_NAME } from '@/views/LearnDashboard/constants';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';
import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks';
import useHideHigherEducationHomeUserUI from '@/views/LearnDashboard/hooks/useHigherEducationHomeUserUI';
import useTeamTracks from '@/views/LearnDashboard/hooks/useTeamTracks/useTeamTracks';
import {
  AMPLITUDE_PLAYROOM_REWARD_BANNER_CTA_SOURCE,
  AMPLITUDE_PLAYROOM_REWARD_BANNER_CTA_SOURCE_DETAIL,
  AMPLITUDE_SCROLL_EVENT_NAME,
  AMPLITUDE_TRACKS_SOURCE,
  AMPLITUDE_TRACKS_SOURCE_DETAIL,
} from '@/views/LearnDashboard/layouts/b2b/StartSection/constants';
import useActionsURL from '@/views/LearnDashboard/layouts/b2b/StartSection/hooks/useActionsURL';
import useGetCanUseInduction from '@/views/LearnDashboard/layouts/b2b/StartSection/hooks/useGetCanUseInduction';
import CoursesThatDontBelongToTracks from '@/views/LearnDashboard/layouts/b2b/StartSection/sections/CoursesThatDontBelongToTracks/LazyCoursesThatDontBelongToTracks';
import OrganizationCourses from '@/views/LearnDashboard/layouts/b2b/StartSection/sections/OrganizationCourses';
import OrganizationLives from '@/views/LearnDashboard/layouts/b2b/StartSection/sections/OrganizationLives';
import RewardsBanner from '@/views/LearnDashboard/layouts/b2b/StartSection/sections/RewardsBanner/RewardsBanner';
import RewardsDialog from '@/views/LearnDashboard/layouts/b2b/StartSection/sections/RewardsDialog';
import Tasks from '@/views/LearnDashboard/layouts/b2b/StartSection/sections/Tasks/Tasks';
import TrackSection from '@/views/LearnDashboard/layouts/b2b/StartSection/sections/TrackSection/TrackSection';
import { redirectToEditStudyPlan } from '@/views/LearnDashboard/layouts/b2b/StartSection/utils/redirectToEditStudyPlan';

import type { StartSectionProps } from './types';

const StartSection: React.FC<StartSectionProps> = ({ me, isDark }) => {
  const { logEvent } = useAmplitude();
  const {
    organizationSlug,
    isPlayroomEnabled,
    isLoadingPlayroomActivated,
    redirectTo,
  } = useLearnDashboardContext();
  const isB2BAllCatalog = !!me.isB2BAllCatalog;
  const organizationId = String(me.organization?.originalId);
  const { matches: isSm } = useMediaQuery('sm');
  const { matches: isMd } = useMediaQuery('md');
  const [isRewardsDialogOpen, setIsRewardsDialogOpen] =
    useState<boolean>(false);
  const { meRole } = useGetCanUseInduction();
  const { t, countryCode } = useCrehanaTranslation();
  const { hasEnrollments } = useLearnLastActivityContext();
  const { getActionClickLearningPlan } = useActionsURL();
  const { playroomLearningPathCoins, playroomCourseCoins } =
    usePlayroomCoinEventValues(organizationSlug as string, organizationId);
  const { currencyCustomName, hasCurrency } = useAvailableCurrencyByUser(
    organizationSlug as string,
  );
  const { teams, loading: loadingTracks } = useTeamTracks({
    organizationSlug: organizationSlug as string,
    me: me,
  });
  const { totalEnrollmentsCount, loading: loadingEnrollments } =
    useCourseEnrollments({
      lastActions: true,
      // We only want to get total enrollments count (totalEnrollmentsCount) for the current user.
      // 'totalEnrollmentsCount' is independent of the 'first' variable.
      first: 1,
    });
  const { plan } = useStudyPlan({
    skip: !isB2BAllCatalog,
  });
  const showMarketplaceUIFF = useHideHigherEducationHomeUserUI();
  const { flagValue: activatedFlag, loading: loadingFF } = useFeatureFlag(
    'CERTIFICATION_WITH_QUIZZES',
  );

  const shouldRenderSetStudyPlan =
    !plan?.progress && (plan?.currentDayIndex || 0) >= 2;
  const isMarketplaceUser =
    me?.organization?.myRole === 'high-education-member';
  const isMarketplaceUserAndUIFF = isMarketplaceUser && showMarketplaceUIFF;
  const shouldRenderCoursesThatDontBelongToTracks = !isMarketplaceUserAndUIFF;
  const shouldRenderCatalogEmptyState =
    isB2BAllCatalog && !isMarketplaceUserAndUIFF;

  const onActionClickLearningPlan = useCallback(
    (
      actionName: StudyPlanActionName,
      amplitude?: Partial<AmplitudeProps>,
    ): void => {
      const urlWithSource = getActionClickLearningPlan(actionName, amplitude);

      redirectTo(urlWithSource);
    },
    [getActionClickLearningPlan, redirectTo],
  );

  const renderLearningPlan = useCallback(() => {
    return (
      <>
        <TrackSectionScroll
          amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
          amplitudeSource="Progress Bar"
        />
        <LearningPlan
          isDark={!!isDark}
          onActionClick={onActionClickLearningPlan}
          onEditStudyPlan={redirectToEditStudyPlan}
          source={LEARN_DASHBOARD_PAGE_NAME}
          className="tw-mb-3.2"
        />
      </>
    );
  }, [isDark, onActionClickLearningPlan]);

  const renderLastActivity = () => {
    return (
      <>
        <TrackSectionScroll
          amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
          amplitudeSource="Last Activity Section"
        />
        {hasEnrollments && (
          <SectionSeparator
            isDark={isDark}
            title={t('B2B_CONTINUE_WITH_LAST_ACTION_TITLE')}
            ctaLabel={t('GO_TO_MY_COURSES')}
            icon={PlaySquare}
            ctaAction={
              isMarketplaceUserAndUIFF
                ? undefined
                : () =>
                    onActionClickLearningPlan('action_completed_courses', {
                      source: 'Home Last Activity',
                      sourceDetail: 'Text Link Courses Last Activity',
                    })
            }
          />
        )}
        <LearnLastActivity
          className={hasEnrollments ? 'tw-my-2.4' : undefined}
        />
      </>
    );
  };

  const renderCoursesThatDontBelongToTracks = () => {
    return (
      <CoursesThatDontBelongToTracks
        isDark={isDark}
        containerType="dashboard"
        languageKey={countryCode as CountryCode}
        organizationSlug={organizationSlug as string}
        playroomCoursesCoin={playroomCourseCoins}
        amplitudeSource="Home Assigned Courses"
        amplitudeSourceDetail="Text Link Assigned Courses"
      >
        <TrackSectionScroll
          amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
          amplitudeSource="Assigned Courses Section"
        />
      </CoursesThatDontBelongToTracks>
    );
  };

  const renderCatalogEmptyState = () => {
    return (
      <Container type="dashboard" className="tw-my-3.2">
        <TrackSectionScroll
          amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
          amplitudeSource="Catalog Empty State Section"
        />
        <CatalogEmptyState
          source={LEARN_DASHBOARD_PAGE_NAME}
          showStudyPlanEmptyState={shouldRenderSetStudyPlan}
        />
      </Container>
    );
  };

  useLayoutEffect(() => {
    const isReadyToSentAmplitudeEventRender =
      !isLoadingPlayroomActivated &&
      !loadingTracks &&
      !loadingEnrollments &&
      !loadingFF;

    if (isReadyToSentAmplitudeEventRender) {
      const amplitudeParameters: Readonly<Record<string, string | number>> = {
        ...getSourceEventParams(),
        ...getCommonAmplitudeProperties(me, isPlayroomEnabled),
        'Learning Path Count': teams.length,
        'Courses Enrolled': totalEnrollmentsCount > 0 ? 'Yes' : 'No',
        'Courses Enrolled Count': totalEnrollmentsCount,
        'Quiz Certification Available': activatedFlag ? 'Yes' : 'No',
      };

      logEvent({
        event: 'Render HomeUser Start',
        data: amplitudeParameters,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingPlayroomActivated,
    isPlayroomEnabled,
    loadingEnrollments,
    loadingTracks,
    teams.length,
    totalEnrollmentsCount,
    loadingFF,
    me,
  ]);

  return (
    <section className="w-full h-full">
      {!isMarketplaceUserAndUIFF && (
        <div className="tw-overflow-hidden">
          <RewardsBanner
            isDark={isDark}
            isPlayroomEnabled={isPlayroomEnabled}
            hasCurrency={hasCurrency}
            amplitudeSource={AMPLITUDE_PLAYROOM_REWARD_BANNER_CTA_SOURCE}
            amplitudeSourceDetail={
              AMPLITUDE_PLAYROOM_REWARD_BANNER_CTA_SOURCE_DETAIL
            }
          />
        </div>
      )}

      <MainTitle
        containerType="dashboard"
        username={me.firstName || me.fullName || me.username || ''}
        className="tw-mt-2.4 sm:tw-mt-3.2 tw-mb-2.4 sm:tw-mb-2"
        hasEnrollments={hasEnrollments}
      />

      {isSm ? (
        <>
          {renderLastActivity()}
          {(!isMarketplaceUserAndUIFF || isMd) && renderLearningPlan()}
        </>
      ) : (
        <>
          {hasEnrollments && (
            <SectionSeparator
              isDark={isDark}
              title={t('B2B_CONTINUE_WITH_LAST_ACTION_TITLE')}
              icon={PlaySquare}
              ctaAction={
                isMarketplaceUserAndUIFF
                  ? undefined
                  : () =>
                      onActionClickLearningPlan('action_completed_courses', {
                        source: 'Home Last Activity',
                        sourceDetail: 'Text Link Courses Last Activity',
                      })
              }
            />
          )}
          <TrackSectionScroll
            amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
            amplitudeSource="Last Activity Section"
          />
          <LearnLastActivity
            className={hasEnrollments ? 'tw-my-2.4' : undefined}
          />
          {!isMarketplaceUserAndUIFF && renderLearningPlan()}
        </>
      )}

      <RewardsDialog
        open={isRewardsDialogOpen}
        onDismiss={() => setIsRewardsDialogOpen(false)}
      />

      {meRole === 'induction' ? (
        <Tasks
          slug={organizationSlug as string}
          userId={String(me.originalId)}
        />
      ) : (
        <>
          {/* Section: Tracks */}
          <TrackSection
            isDark={isDark}
            className="mb-32"
            amplitudeSource={AMPLITUDE_TRACKS_SOURCE}
            amplitudeSourceDetail={AMPLITUDE_TRACKS_SOURCE_DETAIL}
            playroomLearningPathCoins={playroomLearningPathCoins}
            playroomCurrencyName={currencyCustomName}
            loading={loadingTracks}
            teams={teams}
          >
            <TrackSectionScroll
              amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
              amplitudeSource="Tracks Section"
            />
          </TrackSection>
          {isMarketplaceUserAndUIFF && !isMd && renderLearningPlan()}

          {/* Section: Exclusive courses */}
          {!isMarketplaceUserAndUIFF && (
            <OrganizationCourses
              slug={me.organization?.slug || ''}
              user={me}
              isDark={isDark}
              containerType="dashboard"
              amplitudeSource="Home Exclusive Courses"
              amplitudeSourceDetail="Exclusive Courses Cards"
              separatorAmplitudeSourceDetail="Text Link Exclusive Courses"
              playroomCourseCoins={playroomCourseCoins}
            >
              <TrackSectionScroll
                amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
                amplitudeSource="Exclusive Content Section"
              />
            </OrganizationCourses>
          )}

          {/* Section: Lives */}
          <OrganizationLives user={me} isDark={isDark}>
            <TrackSectionScroll
              amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
              amplitudeSource="Exclusive Broadcasts Section"
            />
          </OrganizationLives>

          {hasEnrollments ? (
            <>
              {shouldRenderCoursesThatDontBelongToTracks &&
                renderCoursesThatDontBelongToTracks()}
              {shouldRenderCatalogEmptyState && renderCatalogEmptyState()}
            </>
          ) : (
            <>
              {shouldRenderCatalogEmptyState && renderCatalogEmptyState()}
              {shouldRenderCoursesThatDontBelongToTracks &&
                renderCoursesThatDontBelongToTracks()}
            </>
          )}

          {/* Section: Support */}
          <TrackSectionScroll
            amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
            amplitudeSource="Help Center Section"
          />
          <Support containerType="dashboard" />
        </>
      )}

      <TrackSectionScroll
        amplitudeEventName={AMPLITUDE_SCROLL_EVENT_NAME}
        amplitudeSource="Footer Section"
      />
      <MainFooter
        isDark={isDark}
        ignoreBgTransparent
        className={cn(!isDark && 'bg-white')}
        containerType="dashboard"
        layouts={{
          shouldHideWeAreHiringLink: true,
        }}
      />
    </section>
  );
};

export default StartSection;
