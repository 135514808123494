export default {
  pt: {
    ACTIONS_TITLE: 'Temos mais para você ...',
    // COMMON ACTIONS
    START_ONBOARDING_TITLE: 'Começar onboarding',
    START_ONBOARDING_LABEL: 'Aproveite a Crehana ao máximo ao concluir seu onboarding',
    CREATE_MY_STUDY_PLAN_TITLE: 'Montar meu plano',
    CREATE_MY_STUDY_PLAN_LABEL: 'Organize seu tempo em Crehana com um plano de estudos.',
    EDIT_INTERESTS_TITLE: 'Conferir catálogo',
    EDIT_INTERESTS_LABEL: 'Adicione seus interesses e encontre o curso perfeito para você.',
    MEET_MENTORS_TITLE: 'Conheça os mentores',
    MEET_MENTORS_LABEL: 'Consulte todas as suas dúvidas com os nossos mentores especializados',
    MEET_ACADEMIES_TITLE: 'Conheça Academias',
    MEET_ACADEMIES_LABEL: 'Alcance seus objetivos profissionais com nossas Academias.',
    PREMIUM_BENEFITS_TITLE: 'Exclusivo Premium',
    PREMIUM_BENEFITS_LABEL: 'Conheça todos os benefícios que temos para você',
    SUPPORT_TITLE: 'Ir para suporte',
    SUPPORT_LABEL: 'Estamos aqui para ajudá-lo no que você precisar',
    // MENTORS SECTION
    CHAT_WITH_MENTORS: 'Converse com nossos mentores',
    START_CHAT: 'Iniciar bate-papo',
    START_CHAT_MOBILE: 'Chatea con nuestros mentores',
    SCHEDULE_CALL: 'Marcar uma ligação',
    MENTOR_CARD: 'MENTOR',
    SPECIALIZED_MENTORSHIP: 'Você tem dúvidas sobre o curso?',
    SOLVE_QUESTIONS_SPECIALIZED_MENTOR: 'Converse com nossos mentores',
    MENTORS_CHAT_CTA: 'Vá conversar com o mentor',
    GO_TO_MENTORS_CHAT: 'Vá para o chat',
    FREQUENT_QUESTIONS: 'Perguntas frequentes',
    MENTORSHIP_SERVICE: 'Converse com nossos mentores',
    // STUDY PROGRESS CARD (TODO: TRANSLATE)
    WITHOUT_STUDY_PLAN_TITLE: 'Você ainda não montou seu plano de estudos',
    WITHOUT_STUDY_PLAN_TEXT: 'Com um plano de estudos, você poderá se organizar melhor e alcançar cada um dos seus objetivos na Crehana.',
    CREATE_STUDY_PLAN: 'Montar meu plano de estudos',
    YOUR_CLASS_ACTIVITY: 'Sua atividade nas aulas',
    PROGRESS_MOTIVATION_TEXT: 'Seu ritmo é bom! Continue assim e irá alcançar seu objetivo semanal 💪',
    STUDY_CARD_ERROR_TITLE: 'Houve um erro ao carregar as opções',
    STUDY_CARD_ERROR_TEXT: 'Parece que houve um problema e não pudemos realizar esta ação. Recomendamos que tente novamente.',
    ACHIEVE_GOALS_TEXT: 'Organize-se melhor e alcance seus objetivos.'
  }
};