import { FC, useState } from 'react';
import * as React from 'react';

import Edit from '@crehana/icons/outline/Edit';
import { InverseButton, PrimaryButton } from '@crehana/ui';
import { PhoneInput, usePhoneNumberState } from '@crehana/web';

import {
  useSupportCardTranslation,
  useSupportCardUpdateUserData,
} from '../hooks';

interface PhoneEditorProps {
  onPhoneSuccessEdited?: () => void;
  phoneNumber: string;
  phoneCode: string;
  isDark?: boolean;
  fit?: boolean;
}

const PhoneEditor: FC<React.PropsWithChildren<PhoneEditorProps>> = ({
  phoneNumber,
  phoneCode,
  isDark,
  fit,
  onPhoneSuccessEdited,
}) => {
  const t = useSupportCardTranslation();
  const { phoneState, setPhoneState } = usePhoneNumberState();
  const { updateUserData } = useSupportCardUpdateUserData();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editModeIsActive, setEditModeIsActive] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await updateUserData({
        phoneNumber: phoneState.phoneNumber,
        code: phoneState.code,
      });

      setIsSubmitting(false);
      if (data?.updateUser?.success && onPhoneSuccessEdited) {
        onPhoneSuccessEdited();
      }
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const numberWasNotChange =
    `${phoneCode}${phoneNumber}` ===
    `${phoneState.code}${phoneState.phoneNumber}`;

  const formatNumber = (number: string, code: string) => {
    const parts = number.match(/.{1,3}/g) || [];

    return `+${code} ${parts.join(' ')}`;
  };

  return (
    <section>
      {editModeIsActive ? (
        <>
          <PhoneInput
            transparencyMode={isDark}
            showAll
            onChange={setPhoneState}
            description={t('SUPPORT_CARD_PHONE_EDITOR_DESCRIPTION')}
            initialState={
              phoneNumber
                ? {
                    phoneNumber,
                    code: phoneCode,
                  }
                : undefined
            }
            label={t('SUPPORT_CARD_PHONE_EDITOR_LABEL')}
            autoFocus
          />
          <PrimaryButton
            isDark={isDark}
            full
            size="s"
            className="mt-24"
            disabled={!phoneState.isValid || isSubmitting}
            onClick={
              numberWasNotChange
                ? () => setEditModeIsActive(false)
                : handleSubmit
            }
            label={t('SUPPORT_CARD_PHONE_EDITOR_CONFIRM_NUMBER')}
          />
        </>
      ) : (
        <div>
          {!fit && (
            <>
              {phoneNumber ? (
                <h2 className="font-h2 text-secondary-main text-center">
                  {formatNumber(phoneNumber, phoneCode)}
                </h2>
              ) : (
                <p className="font-body3 text-red-light">
                  {t('SUPPORT_CARD_PHONE_EDITOR_ADVISING_REQUIRED_NUMBER')}
                </p>
              )}
            </>
          )}

          <InverseButton
            white={isDark && !fit}
            isDark={isDark}
            full
            leftIcon={{ component: Edit }}
            size={fit ? 'xs' : 's'}
            className={fit ? '' : 'mt-24'}
            onClick={() => setEditModeIsActive(true)}
            label={t('SUPPORT_CARD_PHONE_EDITOR_CTA_LABEL')}
          />
        </div>
      )}
    </section>
  );
};

export default PhoneEditor;
