import { B2B_ORGANIZATION_LIVES_KEY } from '../constants';

export default {
  [B2B_ORGANIZATION_LIVES_KEY]: {
    TITLE: 'Sesiones exclusivas para ti',
    TITLE_2: 'Transmisiones exclusivas de',
    SEE_ALL_CTA: 'Ver todos',
    CARD_DURATION: `Duración: {{duration}}`,
    CARD_PRESENTED_BY: `Por: {{presenter}}`,
    CARD_CTA_LABEL: 'Ver sesión',
  },
};
