import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useLazyQuery } from "@apollo/client";
import { createUrlWithSourceQueryParams } from "@crehana/analytics";
import { CREHANA_DOMAIN } from "@crehana/constants";
import { transformData } from "@crehana/utils";
var MENTORS_QUERY = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MentorsQuery"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "taxnomyId"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "mentors"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "taxonomy"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "taxnomyId"
            }
          }
        }],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "edges"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "node"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "originalId"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "scheduleText"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "tags"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "taxonomy"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "first"
                      },
                      value: {
                        kind: "IntValue",
                        value: "4"
                      }
                    }],
                    directives: [],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "edges"
                        },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "Field",
                            name: {
                              kind: "Name",
                              value: "node"
                            },
                            arguments: [],
                            directives: [],
                            selectionSet: {
                              kind: "SelectionSet",
                              selections: [{
                                kind: "Field",
                                name: {
                                  kind: "Name",
                                  value: "id"
                                },
                                arguments: [],
                                directives: []
                              }, {
                                kind: "Field",
                                name: {
                                  kind: "Name",
                                  value: "name"
                                },
                                arguments: [],
                                directives: []
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "chatUrl"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "urlScheduleCall"
                    },
                    arguments: [],
                    directives: []
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "user"
                    },
                    arguments: [],
                    directives: [],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "id"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "username"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "mentorScore"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "profilePictureUrl"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "fullName"
                        },
                        arguments: [],
                        directives: []
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "organization"
                        },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "Field",
                            name: {
                              kind: "Name",
                              value: "name"
                            },
                            arguments: [],
                            directives: []
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 542,
    source: {
      body: "query MentorsQuery($taxnomyId: String) {\n  mentors(taxonomy: $taxnomyId) {\n    edges {\n      node {\n        id\n        originalId\n        scheduleText\n        tags\n        taxonomy (first:4) {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        chatUrl\n        urlScheduleCall\n        user {\n          id\n          username\n          mentorScore\n          profilePictureUrl\n          fullName\n          organization {\n            name\n          }\n\n        }\n      }\n    }\n  }\n}",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
import { LAST_ACTIVITY_AMPLITUDE_SECTION_NAME, LAST_ACTIVITY_URLS } from "../constants";
import { useLearnLastActivityContext } from "./useLearnLastActivityContext";
export var useLazyGetMentors = function useLazyGetMentors() {
  var _useLazyQuery = useLazyQuery(MENTORS_QUERY, {
      notifyOnNetworkStatusChange: true
    }),
    _useLazyQuery2 = _slicedToArray(_useLazyQuery, 2),
    mentorsLazyQuery = _useLazyQuery2[0],
    _useLazyQuery2$ = _useLazyQuery2[1],
    data = _useLazyQuery2$.data,
    loading = _useLazyQuery2$.loading,
    error = _useLazyQuery2$.error,
    called = _useLazyQuery2$.called;
  var _useLearnLastActivity = useLearnLastActivityContext(),
    source = _useLearnLastActivity.source,
    disableRedirections = _useLearnLastActivity.disableRedirections;
  var fetchMentors = function fetchMentors(subCategory) {
    mentorsLazyQuery({
      variables: {
        taxnomyId: subCategory ? String(subCategory) : undefined
      }
    });
  };
  var redirectToWeb = function redirectToWeb(url) {
    window.open(createUrlWithSourceQueryParams({
      url: "".concat(CREHANA_DOMAIN).concat(url || LAST_ACTIVITY_URLS.inbox),
      source: source,
      sourceDetail: LAST_ACTIVITY_AMPLITUDE_SECTION_NAME
    }));
  };
  var mentors = (transformData(data === null || data === void 0 ? void 0 : data.mentors) || []).map(function (mentor) {
    var chatUrl = mentor.chatUrl ? createUrlWithSourceQueryParams({
      url: mentor.chatUrl,
      source: source,
      sourceDetail: LAST_ACTIVITY_AMPLITUDE_SECTION_NAME
    }) : LAST_ACTIVITY_URLS.inbox;
    return _objectSpread(_objectSpread({}, mentor), {}, {
      chatUrl: !disableRedirections ? chatUrl : null,
      onGoToInbox: disableRedirections ? function () {
        redirectToWeb(mentor.chatUrl);
      } : undefined,
      onScheduleCall: disableRedirections && mentor.urlScheduleCall ? function () {
        redirectToWeb(mentor.urlScheduleCall);
      } : undefined
    });
  });
  return {
    mentors: mentors,
    loading: loading,
    fetchMentors: fetchMentors,
    called: called,
    error: !!error
  };
};