import { SupportCardType, SupportSegment, SupportType } from './types';

export const SUPPORT_CARD_TRANSLATION_KEY = 'support-card';

const CALENDLY_SUPPORT_PREMIUM: SupportType = {
  imageUrl:
    'https://static.crehana.com/static/img/onboarding-membership/support/schedule_call.png',
  name: 'SUPPORT_CARD_CALENDLY_TITLE',
  description: 'SUPPORT_CARD_CALENDLY_DESCRIPTION',
  descriptionFit: 'SUPPORT_CARD_CALENDLY_DESCRIPTION_FIT',
  cardType: SupportCardType.Cta,
  ctaLabel: 'SUPPORT_CARD_CALENDLY_CTA',
  excludedLanguages: ['br'],
  ctaUrl: 'https://calendly.com/ana-salazar-/premium30min',
};

const CALENDLY_SUPPORT_B2B: SupportType = {
  ...CALENDLY_SUPPORT_PREMIUM,
  ctaUrl: 'https://calendly.com/melissa-bautista/crehana_para_empresas',
};

const CALENDLY_SUPPORT_DEGREE: SupportType = {
  ...CALENDLY_SUPPORT_PREMIUM,
  ctaUrl: 'https://calendly.com/oscar-pazsoldan',
};

const ACADEMIC_ADVISING: SupportType = {
  imageUrl:
    'https://static.crehana.com/static/img/onboarding-membership/support/academic_advising.png',
  name: 'SUPPORT_CARD_ACADEMIC_ADVISING_TITLE',
  description: 'SUPPORT_CARD_ACADEMIC_ADVISING_DESCRIPTION',
  descriptionFit: 'SUPPORT_CARD_ACADEMIC_ADVISING_DESCRIPTION_FIT',
  cardType: SupportCardType.PhoneUpdate,
  excludedLanguages: ['br'],
};

const ACADEMIC_ADVISING_WITH_DIALOG: SupportType = {
  ...ACADEMIC_ADVISING,
  cardType: SupportCardType.Cta,
  ctaLabel: 'SUPPORT_CARD_PHONE_UPDATE_CTA_LABEL',
  excludedLanguages: ['br'],
};

const HELP_CENTER: SupportType = {
  imageUrl:
    'https://static.crehana.com/static/img/onboarding-membership/support/help_center.png',
  name: 'SUPPORT_CARD_HELP_CENTER_TITLE',
  description: 'SUPPORT_CARD_HELP_CENTER_DESCRIPTION',
  descriptionFit: 'SUPPORT_CARD_HELP_CENTER_DESCRIPTION_FIT',
  cardType: SupportCardType.Cta,
  ctaLabel: 'SUPPORT_CARD_HELP_CENTER_CTA',
  ctaUrl: 'https://ayuda.crehana.com/',
};

const HELP_CENTER_PREMIUM: SupportType = {
  ...HELP_CENTER,
  ctaUrl: 'https://ayuda.crehana.com/hc/es/sections/1500000050342-Premium',
};

const HELP_CENTER_B2B: SupportType = {
  ...HELP_CENTER,
  ctaUrl:
    'https://ayuda.crehana.com/hc/es/sections/360009820354-Crehana-para-empresas',
};

const HELP_CENTER_DEGREE: SupportType = {
  ...HELP_CENTER,
  ctaUrl: 'https://ayuda.crehana.com/hc/es/sections/360009804973-MicroDegree',
};

export const HOME_USER_SUPPORT_CARD_LIST_BY_SEGMENTATION: Record<
  SupportSegment,
  SupportType[]
> = {
  premium: [
    HELP_CENTER_PREMIUM,
    CALENDLY_SUPPORT_PREMIUM,
    ACADEMIC_ADVISING_WITH_DIALOG,
  ],
  b2b: [HELP_CENTER_B2B, CALENDLY_SUPPORT_B2B],
  degree: [HELP_CENTER_DEGREE, CALENDLY_SUPPORT_DEGREE],
  payg: [HELP_CENTER],
};
