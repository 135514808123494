import { createUrlWithSourceQueryParams } from '@crehana/utils';

import type { GetOrganizationEnrollmentSet_me_classroomEnrollmentSet_edges_node_course as OrganizationCourse } from '../graphql/types/GetOrganizationEnrollmentSet';

const modifyOrgCourseUrlsWithAmplitude =
  (amplitudeSource: string, amplitudeSourceDetail?: string) =>
  (course: OrganizationCourse): OrganizationCourse => {
    return {
      ...course,
      dashboardUrl: amplitudeSource
        ? createUrlWithSourceQueryParams({
            url: course.dashboardUrl || '',
            source: amplitudeSource,
            sourceDetail: amplitudeSourceDetail,
          })
        : course.dashboardUrl,
    };
  };

export default modifyOrgCourseUrlsWithAmplitude;
