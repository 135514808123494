import { ORGANIZATION_COURSE_CARD_TRANSLATION_KEY } from '../constants';
import { OrganizationCourseCardLocaleKeys } from '../types';

const translations: Record<
  typeof ORGANIZATION_COURSE_CARD_TRANSLATION_KEY,
  Record<OrganizationCourseCardLocaleKeys, string>
> = {
  [ORGANIZATION_COURSE_CARD_TRANSLATION_KEY]: {
    SEE_AGAIN: 'Ver novamente',
    START_COURSE: 'Começar curso',
    CONTINUE_COURSE: 'Continuar curso',
    OWN_COURSE: 'Curso próprio',
    LEVEL: 'Nível',
    DURATION: 'Duração: ',
  },
};

export default translations;
