import { GET_PHONE_SIMPLE_DIALOG_TRANSLATION_KEY } from '../constants';

export default {
  en: {
    [GET_PHONE_SIMPLE_DIALOG_TRANSLATION_KEY]: {
      GFSD_DEFAULT_TITLE: 'Leave us your phone number',
      GFSD_DEFAULT_DESCRIPTION:
        'We will use it to schedule your consultations.',
      GFSD_PHONE_INPUT_LABEL: 'Phone Number',
      GFSD_PHONE_LEGALS:
        'I authorize Crehana to use the provided data to contact me and receive promotions, offers, and information about our products.',
      GFSD_SAVE_NUMBER: 'Save number',
      GFSD_SAVE_NUMBER_ERROR: 'An error occurred, please try again.',
      GFSD_SAVE_NUMBER_SUCCESS: 'Your number has been successfully saved.',
    },
  },
};
