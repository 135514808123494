import { useTranslation, type I18nTranslation } from '@crehana/i18n';

import { B2B_ORGANIZATION_COURSES_KEY } from '../constants';
import en from '../i18n/localeEn';
import es from '../i18n/localeEs';
import pt from '../i18n/localePt';

const LOCALES: I18nTranslation = { es, pt, en };

const useOrganizationCoursesTranslation = () => {
  const { addResourceBundle, ...translationContextValue } = useTranslation(
    B2B_ORGANIZATION_COURSES_KEY,
  );

  addResourceBundle(LOCALES);

  return translationContextValue;
};

export default useOrganizationCoursesTranslation;
