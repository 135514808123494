import { useMemo } from "react";
import { useMediaQuery } from "@crehana/react-hooks";
import useCategoriesQuery from "../../../MainMenu/components/LeftMenu/hooks/useCategoriesQuery";
import { SLUG_CATEGORIES_TO_FILTER } from "../constants/categories";
import { filterCategoriesBySlugs, filterSlugCategoriesByScreenSizes } from "../utils";
export var useFilteredCategories = function useFilteredCategories() {
  var _useCategoriesQuery = useCategoriesQuery(),
    isLoadingAllCategories = _useCategoriesQuery.loading,
    originalAllCategories = _useCategoriesQuery.categories;
  var _useMediaQuery = useMediaQuery({
      only: 'xs'
    }),
    isXs = _useMediaQuery.matches;
  var _useMediaQuery2 = useMediaQuery('sm'),
    isSm = _useMediaQuery2.matches;
  var slugs = useMemo(function () {
    return filterSlugCategoriesByScreenSizes(SLUG_CATEGORIES_TO_FILTER, isXs, isSm);
  }, [isSm, isXs]);
  var categories = useMemo(function () {
    return filterCategoriesBySlugs(originalAllCategories, slugs);
  }, [originalAllCategories, slugs]);
  return {
    categories: categories,
    isLoadingAllCategories: isLoadingAllCategories
  };
};