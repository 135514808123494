import { FC } from 'react';

import cn from 'clsx';

import { InverseButton, LazyImage } from '@crehana/ui';

import PhoneEditor from './components/PhoneEditor';
import { useSupportCardTranslation } from './hooks';
import {
  SupportCardLocaleKeys,
  SupportCardProps,
  SupportCardType,
} from './types';

const SupportCard: FC<React.PropsWithChildren<SupportCardProps>> = ({
  cardInfo,
  isDark,
  isTransparencyMode,
  fit,
  full,
  phoneCode,
  className,
  phoneNumber,
  onPhoneSuccessEdited,
  openGetPhoneDialog,
}) => {
  const t = useSupportCardTranslation();

  const dark = isDark || isTransparencyMode;

  const renderActionComponentByCardType = (
    cardType: SupportCardType,
    ctaUrl?: string,
    ctaLabel?: SupportCardLocaleKeys,
  ) => {
    switch (cardType) {
      case SupportCardType.Cta:
        return (
          <InverseButton
            className="px-8"
            full
            white={dark}
            isDark={dark}
            size={fit ? 'xs' : 's'}
            label={ctaLabel && t(ctaLabel)}
            onClick={() => {
              if (ctaLabel === 'SUPPORT_CARD_PHONE_UPDATE_CTA_LABEL') {
                if (openGetPhoneDialog) {
                  openGetPhoneDialog();
                }
              } else {
                window.open(ctaUrl, '_blank');
              }
            }}
          />
        );
      case SupportCardType.PhoneUpdate:
        return (
          <PhoneEditor
            fit={fit}
            isDark={isDark || isTransparencyMode}
            phoneCode={phoneCode || ''}
            phoneNumber={phoneNumber || ''}
            onPhoneSuccessEdited={onPhoneSuccessEdited}
          />
        );
      case SupportCardType.Default:
      default:
    }
  };

  return (
    <section
      data-testid="support-card"
      className={cn(
        'rounded-5',
        dark
          ? 'bg-base-lighter-dark-mode'
          : 'bg-white border border-solid border-gray-light',
        fit
          ? 'p-12 flex flex-col sm:flex-row sm:items-center h-full min-w-280'
          : 'p-16',
        full && 'w-full',
        className,
      )}
    >
      <div className={cn('flex w-full', fit ? 'items-center' : 'flex-wrap')}>
        <LazyImage
          className={cn('flex-shrink-0', {
            'h-112 w-112 mb-24 sm:mb-0 sm:mr-24': !fit,
            'h-96 w-96 mr-12': fit,
          })}
          imgClassName="object-cover"
          src={cardInfo?.imageUrl}
          alt={t(cardInfo?.name)}
          isDark={dark}
        />

        <div
          className={fit ? 'flex-1 flex flex-col justify-between' : 'sm:flex-1'}
        >
          {!fit && (
            <h4 className="font-h4 mb-8 sm:mb-12">{t(cardInfo?.name)}</h4>
          )}

          <p
            className={cn(
              fit ? 'font-body3' : 'font-body4',
              dark ? 'text-white' : 'text-base-main',
            )}
          >
            {t(fit ? cardInfo.descriptionFit : cardInfo?.description)}
          </p>
          {fit && (
            <footer className="hidden sm:block w-full mt-16">
              {renderActionComponentByCardType(
                cardInfo?.cardType,
                cardInfo?.ctaUrl,
                cardInfo?.ctaLabel,
              )}
            </footer>
          )}
        </div>
      </div>

      <footer className={cn('w-full mt-16', fit && 'block sm:hidden')}>
        {renderActionComponentByCardType(
          cardInfo?.cardType,
          cardInfo?.ctaUrl,
          cardInfo?.ctaLabel,
        )}
      </footer>
    </section>
  );
};

export default SupportCard;
