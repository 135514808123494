import { GET_PHONE_SIMPLE_DIALOG_TRANSLATION_KEY } from '../constants';

export default {
  pt: {
    [GET_PHONE_SIMPLE_DIALOG_TRANSLATION_KEY]: {
      GFSD_DEFAULT_TITLE: 'Deixe-nos o seu número de telefone',
      GFSD_DEFAULT_DESCRIPTION: 'Usaremos para agendar suas consultorias.',
      GFSD_PHONE_INPUT_LABEL: 'Número de telefone',
      GFSD_PHONE_LEGALS:
        'Autorizo ​​a Crehana a utilizar os dados fornecidos para entrar em contato comigo e receber promoções, ofertas e informações sobre nossos produtos',
      GFSD_SAVE_NUMBER: 'Salver número',
      GFSD_SAVE_NUMBER_ERROR: 'Ocorreu um erro. Por favor, tente novamente.',
      GFSD_SAVE_NUMBER_SUCCESS: 'Seu número foi salvo com sucesso.',
    },
  },
};
