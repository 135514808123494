import type { MeObject } from '@crehana/auth';
import { useUserTracks } from '@crehana/web';
import { GetUserTeamsQueryVariables } from '@crehana/web/B2B/LearnTeamTracks/hooks/useUserTracks/graphql/types/GetUserTeamsQuery';

import useParsedTeamCourses from './utils/useParsedTeamCourses';

const useTeamTracks = ({
  organizationSlug,
  me,
  filterBY,
}: { organizationSlug: string; me: MeObject } & Pick<
  GetUserTeamsQueryVariables,
  'filterBY'
>) => {
  const { data, loading, error } = useUserTracks({
    userId: me.originalId as number,
    organizationSlug,
    filterBY,
  });
  const { teams } = useParsedTeamCourses(data);

  return {
    teams,
    loading,
    error,
  };
};

export default useTeamTracks;
