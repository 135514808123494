import { FC, useState } from 'react';

import cn from 'clsx';

import {
  Checkbox,
  Dialog,
  Loader,
  PrimaryButton,
  Snackbar,
  SnackbarProps,
} from '@crehana/ui';
import {
  PhoneInput,
  useContactData,
  useMe,
  usePhoneNumberState,
} from '@crehana/web';

import { useUpdateBasicUserInfo } from '@/views/LearnDashboard/hooks/useUpdateBasicUserInfo';

import { useGetPhoneSimpleDialogTranslation } from './hooks/useGetPhoneSimpleDialogTranslation';
import { GetPhoneSimpleDialogProps } from './types';

const DELAY_TO_DISMISS_SNACKBAR = 3000;

const GetPhoneSimpleDialog: FC<
  React.PropsWithChildren<GetPhoneSimpleDialogProps>
> = ({
  open,
  isDark,
  title,
  description,
  showLegalsCheckbox,
  hideDialog,
  sendToCallCenter,
  onSuccess,
}) => {
  const t = useGetPhoneSimpleDialogTranslation();
  const { phoneState, setPhoneState } = usePhoneNumberState();
  const { saveContactData } = useContactData();

  const [legalCheckbox, setLegalCheckbox] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState<{
    open: boolean;
    state?: SnackbarProps['state'];
    message?: string;
    onDismiss?: () => void;
  }>({
    open: false,
  });

  const { updateUserInfo } = useUpdateBasicUserInfo();
  const { loading: loadingUserData, me } = useMe();

  const onSnackbarDismiss = (isSuccess: boolean) => {
    setSnackbarInfo({ open: false });
    if (isSuccess) {
      hideDialog();
    }
  };

  const handleError = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      open: true,
      state: 'alert',
      message: t('GFSD_SAVE_NUMBER_ERROR'),
      onDismiss: () => onSnackbarDismiss(false),
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await updateUserInfo({
        phoneNumber: phoneState.phoneNumber,
        code: phoneState.code,
      });

      if (sendToCallCenter?.enabled) {
        await saveContactData({
          source: sendToCallCenter.source,
          phoneNumber: phoneState.fullNumber,
          name: me?.fullName!,
          email: me?.email,
        });
      }

      if (data?.updateUser?.success) {
        setSnackbarInfo({
          open: true,
          state: 'success',
          message: t('GFSD_SAVE_NUMBER_SUCCESS'),
          onDismiss: () => onSnackbarDismiss(true),
        });
        if (onSuccess) {
          onSuccess();
        }
      } else {
        handleError();
      }

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      handleError();
    }
  };

  if (loadingUserData) {
    return (
      <div className="flex items-center justify-center w-full h-360 p-36">
        <Loader />
      </div>
    );
  }

  const currentPhoneCode = me?.phoneCode || '';
  const currentPhoneNumber = me?.phoneNumber || '';

  const numberWasNotChange =
    `${currentPhoneCode}${currentPhoneNumber}` ===
    `${phoneState.code}${phoneState.phoneNumber}`;

  const disableButton =
    isSubmitting ||
    numberWasNotChange ||
    !phoneState.isValid ||
    (showLegalsCheckbox && !legalCheckbox);

  return (
    <Dialog onDismiss={hideDialog} isDark={isDark} open={open} size="s">
      <article
        className={cn('py-36 px-16 mx-auto', {
          'text-base-main': !isDark,
          'text-white': isDark,
        })}
        style={{
          maxWidth: 480,
        }}
      >
        <h4 className="font-h4 text-center">
          {title || t('GFSD_DEFAULT_TITLE')}
        </h4>

        <p className="font-body3 text-center mt-16">
          {description || t('GFSD_DEFAULT_DESCRIPTION')}
        </p>

        <div className="mt-24 min-h-104">
          <PhoneInput
            isDark={isDark}
            showAll
            onChange={setPhoneState}
            initialState={
              currentPhoneNumber && currentPhoneCode
                ? {
                    phoneNumber: currentPhoneNumber,
                    code: currentPhoneCode,
                  }
                : undefined
            }
            label={t('GFSD_PHONE_INPUT_LABEL')}
            autoFocus
          />
        </div>

        {showLegalsCheckbox && (
          <Checkbox
            onChange={e => setLegalCheckbox(e.target.checked)}
            checked={legalCheckbox}
            isDark={isDark}
            className="mt-24 text-left "
            label={<p className="font-legals">{t('GFSD_PHONE_LEGALS')}</p>}
          />
        )}

        <footer className="text-center mt-40">
          <PrimaryButton
            onClick={handleSubmit}
            disabled={disableButton}
            isDark={isDark}
            className="mx-auto w-full sm:w-auto"
            size="l"
            label={t('GFSD_SAVE_NUMBER')}
          />
        </footer>
      </article>
      <Snackbar
        dismissOnDelay={DELAY_TO_DISMISS_SNACKBAR}
        isDark={isDark}
        open={snackbarInfo.open}
        onDismiss={snackbarInfo.onDismiss}
        message={snackbarInfo.message}
        state={snackbarInfo.state}
        position="top-right"
      />
    </Dialog>
  );
};

export default GetPhoneSimpleDialog;
