import { useQuery } from "@apollo/client";
var CAN_USE_INDUCTION_QUERY = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "LearnHomeUserCanUseInductionQuery"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "orgSlug"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "organization"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "slug"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "orgSlug"
            }
          }
        }],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "configuration"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "canUseInduction"
                },
                arguments: [],
                directives: []
              }]
            }
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 147,
    source: {
      body: "query LearnHomeUserCanUseInductionQuery($orgSlug: String) {\n  organization(slug: $orgSlug) {\n    configuration {\n      canUseInduction\n    }\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
var useCanUseInductionQuery = function useCanUseInductionQuery(_ref) {
  var _data$organization, _data$organization$co;
  var orgSlug = _ref.orgSlug;
  var _useQuery = useQuery(CAN_USE_INDUCTION_QUERY, {
      context: {
        clientName: 'b2b'
      },
      variables: {
        orgSlug: orgSlug
      },
      skip: !orgSlug
    }),
    data = _useQuery.data,
    loading = _useQuery.loading,
    error = _useQuery.error;
  var canUseInduction = !!(data !== null && data !== void 0 && (_data$organization = data.organization) !== null && _data$organization !== void 0 && (_data$organization$co = _data$organization.configuration) !== null && _data$organization$co !== void 0 && _data$organization$co.canUseInduction);
  return {
    canUseInduction: canUseInduction,
    loading: loading,
    error: error
  };
};
export default useCanUseInductionQuery;