export default {
  es: {
    ACTIONS_TITLE: 'Tenemos más para ti...',
    // COMMON ACTIONS
    START_ONBOARDING_TITLE: 'Iniciar onboarding',
    START_ONBOARDING_LABEL: 'Disfruta Crehana al máximo completando tu onboarding.',
    CREATE_MY_STUDY_PLAN_TITLE: 'Armar mi plan',
    CREATE_MY_STUDY_PLAN_LABEL: 'Organiza tu tiempo en Crehana con un plan de estudios.',
    EDIT_INTERESTS_TITLE: 'Editar intereses',
    EDIT_INTERESTS_LABEL: 'Agrega tus intereses y encuentra el curso perfecto para ti.',
    MEET_MENTORS_TITLE: 'Conocer Mentores',
    MEET_MENTORS_LABEL: 'Consulta toda tus dudas con nuestros mentores especialistas.',
    MEET_ACADEMIES_TITLE: 'Conoce Academias',
    MEET_ACADEMIES_LABEL: 'Alcanza tus objetivos profesionales con nuestras Academias.',
    PREMIUM_BENEFITS_TITLE: 'Exclusivo Premium',
    PREMIUM_BENEFITS_LABEL: 'Conoce todos los beneficios que tenemos para ti.',
    SUPPORT_TITLE: 'Ir a Soporte',
    SUPPORT_LABEL: 'Estamos aquí para ayudarte en lo que necesites.',
    // MENTORS SECTION
    CHAT_WITH_MENTORS: 'Chatea con nuestros mentores',
    START_CHAT: 'Iniciar chat',
    START_CHAT_MOBILE: 'Chatea con nuestros mentores',
    SCHEDULE_CALL: 'Agendar llamada',
    MENTOR_CARD: 'MENTOR',
    SPECIALIZED_MENTORSHIP: '¿Tienes dudas sobre el curso?',
    SOLVE_QUESTIONS_SPECIALIZED_MENTOR: 'Chatea con nuestros mentores',
    MENTORS_CHAT_CTA: 'Ir al chat de mentores',
    GO_TO_MENTORS_CHAT: 'Ir al chat',
    FREQUENT_QUESTIONS: 'Preguntas frecuentes',
    MENTORSHIP_SERVICE: 'Chatea con nuestros mentores',
    // STUDY PROGRESS CARD
    WITHOUT_STUDY_PLAN_TITLE: 'Aún no armas tu plan de estudios',
    WITHOUT_STUDY_PLAN_TEXT: 'Con un plan de estudios podrás organizarte mejor y alcanzar cada uno de tus objetivos en Crehana.',
    CREATE_STUDY_PLAN: 'Armar mi plan de estudios',
    YOUR_CLASS_ACTIVITY: 'Tu actividad en clases',
    PROGRESS_MOTIVATION_TEXT: '¡Vas a buen ritmo! Sigue así y llegarás a tu objetivo semanal 💪',
    STUDY_CARD_ERROR_TITLE: 'Hubo un error al cargar las opciones',
    STUDY_CARD_ERROR_TEXT: 'Parece que ocurrió un problema y no pudimos realizar esta acción. Te recomendamos que lo vuelvas a intentar.',
    ACHIEVE_GOALS_TEXT: 'Organizate mejor y alcanza tus objetivos.'
  }
};