import { ORGANIZATION_COURSE_CARD_TRANSLATION_KEY } from '../constants';

export default {
  [ORGANIZATION_COURSE_CARD_TRANSLATION_KEY]: {
    SEE_AGAIN: 'Ver de nuevo',
    START_COURSE: 'Empezar curso',
    CONTINUE_COURSE: 'Continuar curso',
    OWN_COURSE: 'Curso propio',
    LEVEL: 'Nivel',
    DURATION: 'Duración: ',
  },
};
