import { DATE_LOCALES } from "@crehana/utils";
export var getWeekDaysInfo = function getWeekDaysInfo(langKey) {
  var languageKey = langKey;
  var WEEK_DAYS_LOCALES = DATE_LOCALES[languageKey].dayNamesShort;
  return {
    Monday: {
      index: 0,
      value: 1,
      label: WEEK_DAYS_LOCALES[1],
      initial: WEEK_DAYS_LOCALES[1].substring(0, 1)
    },
    Tuesday: {
      index: 1,
      value: 2,
      label: WEEK_DAYS_LOCALES[2],
      initial: WEEK_DAYS_LOCALES[2].substring(0, 1)
    },
    Wednesday: {
      index: 2,
      value: 3,
      label: WEEK_DAYS_LOCALES[3],
      initial: WEEK_DAYS_LOCALES[3].substring(0, 1)
    },
    Thursday: {
      index: 3,
      value: 4,
      label: WEEK_DAYS_LOCALES[4],
      initial: WEEK_DAYS_LOCALES[4].substring(0, 1)
    },
    Friday: {
      index: 4,
      value: 5,
      label: WEEK_DAYS_LOCALES[5],
      initial: WEEK_DAYS_LOCALES[5].substring(0, 1)
    },
    Saturday: {
      index: 5,
      value: 6,
      label: WEEK_DAYS_LOCALES[6],
      initial: WEEK_DAYS_LOCALES[6].substring(0, 1)
    },
    Sunday: {
      index: 6,
      value: 0,
      label: WEEK_DAYS_LOCALES[0],
      initial: WEEK_DAYS_LOCALES[0].substring(0, 1)
    }
  };
};