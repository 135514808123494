import { B2B_ORGANIZATION_COURSES_KEY } from '../constants';

const en = {
  [B2B_ORGANIZATION_COURSES_KEY]: {
    TITLE: 'Courses from',
    TITLE_2: 'exclusive for you',
    SEE_ALL_CTA: 'See all',
  },
};

export default en;
