import { B2B_ORGANIZATION_COURSES_KEY } from '../constants';

const localPT = {
  [B2B_ORGANIZATION_COURSES_KEY]: {
    TITLE: 'Cursos da',
    TITLE_2: 'exclusivos para você',
    SEE_ALL_CTA: 'Ver tudo',
  },
};

export default localPT;
