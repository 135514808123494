import type { TSetTranslationKeys } from '@crehana/ts-types';

import type { supportCardLocaleEs } from './i18n';

export interface SupportType {
  imageUrl: string;
  name: SupportCardLocaleKeys;
  description: SupportCardLocaleKeys;
  descriptionFit: SupportCardLocaleKeys;
  cardType: SupportCardType;
  ctaUrl?: string;
  ctaLabel?: SupportCardLocaleKeys;
  excludedLanguages?: string[];
}

export interface SupportCardProps {
  cardInfo: SupportType;
  isDark?: boolean;
  full?: boolean;
  fit?: boolean;
  className?: string;
  isTransparencyMode?: boolean;
  phoneCode?: string;
  phoneNumber?: string;
  onPhoneSuccessEdited?: () => void;
  openGetPhoneDialog?: () => void;
}

export enum SupportCardType {
  Cta = 'cta',
  PhoneUpdate = 'phone-update',
  Default = 'default',
}

export type SupportSegment = 'premium' | 'b2b' | 'degree' | 'payg';

export type SupportCardLocaleKeys = TSetTranslationKeys<
  typeof supportCardLocaleEs.es
>;
