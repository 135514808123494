export var SLUG_CATEGORIES_TO_FILTER = [{
  slug: 'marketing',
  renderAllScreenSizes: true
}, {
  slug: 'liderazgo',
  renderAllScreenSizes: true
}, {
  slug: 'desarrollo-personal',
  renderAllScreenSizes: true
}, {
  slug: 'transformacion-digital',
  renderAllScreenSizes: true
}, {
  slug: 'inteligencia-y-ciencia-de-datos',
  renderAllScreenSizes: true
}, {
  slug: 'ventas-y-experiencia-del-cliente',
  renderOnSm: true
}, {
  slug: 'diseno-y-desarrollo-de-productos-digitales',
  renderOnSm: true
}, {
  slug: 'productividad-y-software',
  renderOnSm: true
}, {
  slug: 'gestion-de-operaciones',
  renderOnSm: true
}];