import { useTranslation, type I18nTranslation } from '@crehana/i18n';
import type { TSetTranslationKeys } from '@crehana/ts-types';

import { B2B_ORGANIZATION_LIVES_KEY } from '../constants';
import en from '../i18n/localeEn';
import es from '../i18n/localeEs';
import pt from '../i18n/localePt';

const LOCALES: I18nTranslation = { es, pt, en };

export type OrganizationLivesLocaleKeys = TSetTranslationKeys<typeof es>;

const useOrganizationLivesTranslations = () => {
  const { addResourceBundle, ...translationContextValue } =
    useTranslation<OrganizationLivesLocaleKeys>(B2B_ORGANIZATION_LIVES_KEY);

  addResourceBundle(LOCALES);

  return translationContextValue;
};

export default useOrganizationLivesTranslations;
