/**
 * @category Date format
 *
 * @summary Show time in seconds as mm:ss or hh:mm:ss
 *
 * @param {number} timeInSeconds - The time in seconds
 * @param {boolean} ignoreHours - If true, timestamp will always be mm:ss even for great numbers
 *
 * @example
 * console.log(toMinutes(244)); // 04:04
 * console.log(toMinutes(7800)); // 02:10:00
 * console.log(toMinutes(7800, true)); // 130:00
 */
var formatTimestamp = function formatTimestamp(timeInSeconds, ignoreHours) {
  var minutes = Math.floor(timeInSeconds / 60);
  var seconds = Math.ceil(timeInSeconds - minutes * 60);
  if (!ignoreHours && minutes >= 60) {
    var hours = Math.floor(minutes / 60);
    minutes = Math.ceil(minutes - hours * 60);
    return "".concat("0".concat(hours).slice(-2), ":").concat("0".concat(minutes).slice(-2), ":").concat("0".concat(seconds).slice(-2));
  }
  return "".concat(ignoreHours && minutes >= 10 ? minutes : "0".concat(minutes).slice(-2), ":").concat("0".concat(seconds).slice(-2));
};
export default formatTimestamp;