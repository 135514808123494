import * as React from 'react';

import cn from 'clsx';

import { Container, Slider, type ContainerProps } from '@crehana/ui';
import { useUITheme } from '@crehana/web';

import GetPhoneSimpleDialog from './components/GetPhoneSimpleDialog';
import SupportCard, {
  HOME_USER_SUPPORT_CARD_LIST_BY_SEGMENTATION,
  type SupportSegment,
} from './components/SupportCard';
import useSupportTranslation from './hooks/useSupportTranslation';

type SupportProps = {
  hasActiveDegree?: boolean;
  isPremium?: boolean;
  isB2B?: boolean;
  containerType?: ContainerProps['type'];
};

const Support: React.FC<SupportProps> = ({
  hasActiveDegree,
  isPremium,
  isB2B,
  containerType,
}) => {
  const { t, countryCode } = useSupportTranslation();
  const { isDark } = useUITheme();

  const [getPhoneSimpleDialogIsOpen, setGetPhoneSimpleDialogIsOpen] =
    React.useState(false);

  let segment: SupportSegment = 'payg';

  if (isPremium) {
    segment = 'premium';
  }
  if (isB2B) {
    segment = 'b2b';
  }
  if (hasActiveDegree) {
    segment = 'degree';
  }

  const SUPPORT_CARDS = HOME_USER_SUPPORT_CARD_LIST_BY_SEGMENTATION[
    segment
  ].filter(
    ({ excludedLanguages }) =>
      !excludedLanguages || !excludedLanguages.includes(countryCode!),
  );

  return (
    <section className={cn(!isDark && 'bg-gray-lighter')}>
      <Container className="pb-0 py-20 sm:pb-20" type={containerType}>
        <h6
          className={cn(
            `font-subtitle2 mb-8 text-left`,
            isDark ? 'text-white' : 'text-base-main',
          )}
        >
          {t('SUPPORT_SECTION_TITLE')}
        </h6>
        <p
          className={cn(
            `font-body3 mb-20 text-left`,
            isDark ? 'text-gray-main' : 'text-base-lighter',
          )}
        >
          {t('SUPPORT_SECTION_DESCRIPTION')}
        </p>

        <Slider
          itemsPerPage={{ xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}
          dark={isDark}
          mobilePaginationProps={{ type: 'default-s' }}
          desktopPaginationType="dots"
          desktopPaginationSelector=".support-cards"
        >
          {SUPPORT_CARDS.map(cardInfo => (
            <SupportCard
              isDark={isDark}
              fit
              full
              key={`home-user-${cardInfo.ctaLabel}`}
              cardInfo={cardInfo}
              openGetPhoneDialog={() => setGetPhoneSimpleDialogIsOpen(true)}
            />
          ))}
        </Slider>
        <div className="support-cards flex justify-center mt-24" />
      </Container>
      <GetPhoneSimpleDialog
        open={getPhoneSimpleDialogIsOpen}
        isDark={isDark}
        hideDialog={() => setGetPhoneSimpleDialogIsOpen(false)}
      />
    </section>
  );
};

export default Support;
