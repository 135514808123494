import { createUrlWithSourceQueryParams } from '@crehana/analytics';
import { useMe, useStudentUrls, type MeObject } from '@crehana/auth';
import { usePlayroomCoinEventValues } from '@crehana/compass.playroom';
import { Container, Loader } from '@crehana/compass.ui';
import { useFeatureFlag } from '@crehana/feature-flags/react';
import { LearnLastActivityProvider } from '@crehana/web';

import { LEARN_DASHBOARD_PAGE_NAME } from '../../../constants';
import { useLearnDashboardContext } from '../../../context';
import { useCannyUrl } from '../../../hooks';
import {
  AMPLITUDE_LAST_ACTIVITY_SOURCE,
  AMPLITUDE_LAST_ACTIVITY_SOURCE_DETAIL,
} from './constants';
import type { StartSectionProps } from './types';

export const withProviders = (Component: React.FC<StartSectionProps>) => {
  const StartSectionWithProviders = () => {
    const { me } = useMe();
    const cannyUrl = useCannyUrl({ skip: !me!.hasOrganization });
    const organizationId = me?.organization?.originalId?.toString() as string;
    const { isDark, organizationSlug, redirectTo } = useLearnDashboardContext();
    const { flagValue: activatedFlag, loading: loadingFF } = useFeatureFlag(
      'API_V5_CONTENT_DISCOVERY',
    );
    const { myCoursesUrl, myTracksUrl } = useStudentUrls();
    const { playroomCourseCoins, playroomProjectCoins, playroomQuizCoins } =
      usePlayroomCoinEventValues(organizationSlug as string, organizationId);

    const myCoursesUrlWithSource = createUrlWithSourceQueryParams({
      url: myCoursesUrl,
      source: AMPLITUDE_LAST_ACTIVITY_SOURCE,
      sourceDetail: AMPLITUDE_LAST_ACTIVITY_SOURCE_DETAIL,
    });

    const myTracksUrlWithSource = createUrlWithSourceQueryParams({
      url: myTracksUrl,
      source: AMPLITUDE_LAST_ACTIVITY_SOURCE,
    });

    return loadingFF ? (
      <Container className="tw-flex tw-h-full tw-items-center tw-justify-center tw-w-full">
        <Loader />
      </Container>
    ) : (
      <LearnLastActivityProvider
        me={me as MeObject}
        isDark={isDark}
        containerType="dashboard"
        feedbackUrl={cannyUrl}
        amplitudePageName={LEARN_DASHBOARD_PAGE_NAME}
        onGoToB2BTracksDetail={() => redirectTo(myTracksUrlWithSource)}
        userDashboardUrl={myCoursesUrlWithSource}
        playroomCoursesCoinValue={playroomCourseCoins}
        playroomProjectCoinValue={playroomProjectCoins}
        playroomQuizCoinValue={playroomQuizCoins}
        hideSliderNewCourses
        showNewSection={activatedFlag}
      >
        <Component
          me={me as MeObject}
          isDark={isDark}
          canNewSectionLearning={activatedFlag}
        />
      </LearnLastActivityProvider>
    );
  };

  StartSectionWithProviders.displayName = `StartSectionWithProviders`;

  return StartSectionWithProviders;
};
