import { useTranslation } from '@crehana/i18n';

import { GET_PHONE_SIMPLE_DIALOG_TRANSLATION_KEY } from '../constants';
import {
  getPhoneSimpleDialogLocaleEn,
  getPhoneSimpleDialogLocaleEs,
  getPhoneSimpleDialogLocalePt,
} from '../i18n';
import { GetPhoneSimpleDialogLocaleKeys } from '../types';

export const useGetPhoneSimpleDialogTranslation = () => {
  const { t, addResourceBundle } =
    useTranslation<GetPhoneSimpleDialogLocaleKeys>(
      GET_PHONE_SIMPLE_DIALOG_TRANSLATION_KEY,
    );

  addResourceBundle({
    es: getPhoneSimpleDialogLocaleEs.es,
    pt: getPhoneSimpleDialogLocalePt.pt,
    en: getPhoneSimpleDialogLocaleEn.en,
  });

  return t;
};
