import { lazy, Suspense, useMemo } from 'react';
import * as React from 'react';

import cn from 'clsx';

import { formatTimestamp } from '@crehana/date';
import Clock2 from '@crehana/icons/Clock2';
import {
  Clamp,
  colors,
  LazyImage,
  PrimaryButton,
  ProgressBar,
  TextTag,
} from '@crehana/ui';

import OrgCourseLevelIcon from './components/OrgCourseLevelIcon';
import useOrganizationCourseTranslations from './hooks/useOrganizationCourseTranslations';
import type { OrganizationCourseCardProps } from './types';

const { baseLighter } = colors;

// Lazy component to avoid loading PlayroomRewardIndicator in the main bundle and because it's not used in some cases.
const LazyPlayroomRewardIndicatorWithRive = lazy(() =>
  import('@crehana/compass.playroom').then(module => ({
    default: module.PlayroomRewardIndicatorWithRive,
  })),
);

const OrganizationCourseCard: React.FC<
  React.PropsWithChildren<OrganizationCourseCardProps>
> = ({
  promoImage,
  title,
  isDark,
  duration,
  progress,
  url,
  level,
  playroomCourseCoins = -1,
  className,
}) => {
  const { t } = useOrganizationCourseTranslations();
  const renderLabel = useMemo(() => {
    if (progress === 100) {
      return t('SEE_AGAIN');
    }
    if (progress === 0) {
      return t('START_COURSE');
    }
    return t('CONTINUE_COURSE');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);
  const shouldRenderPlayroomIndicator =
    typeof progress === 'number' && progress < 100 && playroomCourseCoins > 0;

  return (
    <div className="shadow-tooltip">
      <div className="relative">
        <div className="absolute z-1 tw-top-1.2 tw-left-1.2">
          <TextTag label={t('OWN_COURSE')} />
        </div>
        {shouldRenderPlayroomIndicator && (
          <Suspense fallback={null}>
            <LazyPlayroomRewardIndicatorWithRive
              coins={playroomCourseCoins}
              className="tw-absolute tw-top-0 tw-right-0 tw-z-1 tw-mt-0.8 tw-mr-0.8"
            />
          </Suspense>
        )}
        <a href={url}>
          <LazyImage
            className="w-full h-160 cursor-pointer"
            imgClassName="object-cover"
            src={promoImage}
            imgxParams={{ h: 160, dpr: 2, crop: 'faces' }}
            alt={title}
          />
        </a>
      </div>

      <ProgressBar
        progress={progress || 0}
        isDark={isDark}
        className="w-full"
      />
      <div className={cn('p-16', isDark && 'bg-base-lighter-16', className)}>
        <div className="mb-16">
          <a href={url}>
            <Clamp
              className={cn(
                `font-subtitle3 h-40 mb-8 cursor-pointer`,
                isDark ? 'text-white' : 'text-base-main',
              )}
              clamp={2}
            >
              {title}
            </Clamp>
          </a>
          <div className="flex items-center mb-16">
            {level && (
              <div className="flex items-center">
                <OrgCourseLevelIcon originalId={level.originalId} />
                <p
                  className={cn(
                    `font-body4`,
                    isDark ? 'text-white' : 'text-gray-dark',
                  )}
                >
                  {t('LEVEL')} {level.name}
                </p>
              </div>
            )}
          </div>
          {!!duration && (
            <div className="flex items-center mb-16">
              <Clock2 color={baseLighter} size={18} className="mr-8" />
              <p
                className={cn(
                  `font-body4`,
                  isDark ? 'text-white' : 'text-base-main',
                )}
              >
                {t('DURATION')} {formatTimestamp(duration)}
              </p>
            </div>
          )}
        </div>
        <PrimaryButton full label={renderLabel} href={url} isDark={isDark} />
      </div>
    </div>
  );
};

export default OrganizationCourseCard;
