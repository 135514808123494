import type { I18nTranslation } from '@crehana/i18n';

import en from './localeEn';
import es from './localeEs';
import pt from './localePt';

export const localeEs = es;
export const localePt = pt;

const locales: I18nTranslation = { es, pt, en };

export default locales;
