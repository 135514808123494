import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useMutation } from "@apollo/client";
var ADD_CONTACT_DATA_MUTATION = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "AddContactData"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "name"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "email"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "birthDate"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "phoneNumber"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "source"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ContactSourceEnum"
          }
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "categoryId"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      },
      directives: []
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "absoluteUrl"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "addContactData"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "input"
          },
          value: {
            kind: "ObjectValue",
            fields: [{
              kind: "ObjectField",
              name: {
                kind: "Name",
                value: "name"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "name"
                }
              }
            }, {
              kind: "ObjectField",
              name: {
                kind: "Name",
                value: "email"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "email"
                }
              }
            }, {
              kind: "ObjectField",
              name: {
                kind: "Name",
                value: "birthDate"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "birthDate"
                }
              }
            }, {
              kind: "ObjectField",
              name: {
                kind: "Name",
                value: "phoneNumber"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "phoneNumber"
                }
              }
            }, {
              kind: "ObjectField",
              name: {
                kind: "Name",
                value: "source"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "source"
                }
              }
            }, {
              kind: "ObjectField",
              name: {
                kind: "Name",
                value: "categoryId"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "categoryId"
                }
              }
            }, {
              kind: "ObjectField",
              name: {
                kind: "Name",
                value: "absoluteUrl"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "absoluteUrl"
                }
              }
            }]
          }
        }],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "success"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "token"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "response"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "code"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "message"
                },
                arguments: [],
                directives: []
              }]
            }
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 478,
    source: {
      body: "mutation AddContactData(\n  $name: String!\n  $email: String\n  $birthDate: String\n  $phoneNumber: String\n  $source: ContactSourceEnum!\n  $categoryId: ID\n  $absoluteUrl: String\n) {\n  addContactData(\n    input: {\n      name: $name\n      email: $email\n      birthDate: $birthDate\n      phoneNumber: $phoneNumber\n      source: $source\n      categoryId: $categoryId\n      absoluteUrl: $absoluteUrl\n    }\n  ) {\n    success\n    token\n\n    response {\n      code\n      message\n    }\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
export var useContactData = function useContactData() {
  var _useMutation = useMutation(ADD_CONTACT_DATA_MUTATION),
    _useMutation2 = _slicedToArray(_useMutation, 1),
    saveContactDataMutation = _useMutation2[0];
  var saveContactData = function saveContactData(variables) {
    var _variables$name, _variables$absoluteUr;
    // Important: Substring some variables to match backend to prevent backend bad request
    var _variables = _objectSpread(_objectSpread({}, variables), {}, {
      name: variables === null || variables === void 0 ? void 0 : (_variables$name = variables.name) === null || _variables$name === void 0 ? void 0 : _variables$name.substring(0, 50),
      absoluteUrl: (_variables$absoluteUr = variables.absoluteUrl) === null || _variables$absoluteUr === void 0 ? void 0 : _variables$absoluteUr.substring(0, 200)
    });
    return saveContactDataMutation({
      variables: _variables
    }).then(function (res) {
      var _res$data, _res$data$addContactD;
      return {
        success: !!((_res$data = res.data) !== null && _res$data !== void 0 && (_res$data$addContactD = _res$data.addContactData) !== null && _res$data$addContactD !== void 0 && _res$data$addContactD.success)
      };
    })["catch"](function () {
      return {
        success: false
      };
    });
  };
  return {
    saveContactData: saveContactData
  };
};
export default useContactData;