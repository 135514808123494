export default {
  pt: {
    HU_B2B_ACTIONS_TITLE: 'Temos mais para você ...',
    HU_B2B_ACTIONS_DESCRIPTION: 'Seu administrador irá atribuir a você um caminho de aprendizagem. Enquanto isso, explore os seguintes conteúdos que temos para você.',
    HU_B2B_START_TEST_SKILL_XP: 'Faça o seu teste de Skill XP',
    HU_B2B_START_TEST_SKILL_XP_CTA: 'Começar Skill XP',
    HU_B2B_MENTORS: 'Consulte suas dúvidas com nossos mentores',
    HU_B2B_MENTORS_CTA: 'Conheça os mentores',
    HU_B2B_BLOG: 'Confira nosso material complementar',
    HU_B2B_BLOG_CTA: 'Ir para o blog',
    HU_B2B_SUPPORT: 'Dúvidas? Estamos aqui para ajudá-lhe',
    HU_B2B_SUPPORT_CTA: 'Ir para a Central de Ajuda'
  }
};