import { useMutation } from '@apollo/client';

import UPDATE_USER from '../graphql/SupportCardUpdateUserData.graphql';
import {
  SupportCardUpdateUserData,
  SupportCardUpdateUserDataVariables,
} from '../graphql/types/SupportCardUpdateUserData';

export const useSupportCardUpdateUserData = () => {
  const [updateUserInfo] = useMutation<
    SupportCardUpdateUserData,
    SupportCardUpdateUserDataVariables
  >(UPDATE_USER);

  const updateUserData = (userData: SupportCardUpdateUserDataVariables) => {
    return updateUserInfo({
      variables: userData,
    });
  };

  return { updateUserData };
};
