import { B2B_ORGANIZATION_COURSES_KEY } from '../constants';

const localES = {
  [B2B_ORGANIZATION_COURSES_KEY]: {
    TITLE: 'Cursos exclusivos de',
    TITLE_2: 'para ti',
    SEE_ALL_CTA: 'Ver todos',
  },
};
export default localES;
