import * as React from 'react';
import { Suspense } from 'react';

import dynamic from 'next/dynamic';

import { Container, Loader } from '@crehana/ui';

import { StartSectionProps } from '../../types';
import type { CoursesThatDontBelongToTracksProps } from './types';

const CoursesThatDontBelongToTracks = dynamic(
  () =>
    import(
      /* webpackChunkName: "CoursesThatDontBelongToTracks" */ './CoursesThatDontBelongToTracks'
    ),
);

const LazyCoursesThatDontBelongToTracks: React.FC<
  React.PropsWithChildren<CoursesThatDontBelongToTracksProps> &
    Partial<Pick<StartSectionProps, 'me'>>
> = props => {
  return (
    <Suspense
      fallback={
        <Container className="w-full flex justify-center">
          <Loader />
        </Container>
      }
    >
      <CoursesThatDontBelongToTracks {...props} />
    </Suspense>
  );
};

export default LazyCoursesThatDontBelongToTracks;
